import { useCallback, useState } from 'react';
import { t } from '../../localization';
import { isExistKey } from '../locale/i18nToLocalize';
export const CONNECTION_ABORTED_CODE = 'ECONNABORTED';
export const UNKNOWN_ERROR_MESSAGE_CODE = 'UnknownError';
const ERRORS_TRANSLATION_NAMESPACE = 'Errors';
const isArgsCoords = (args) => Boolean(args.coordinates);
const isArgsCell = (args) => Boolean(args.cellValue);
const isArgsSize = (args) => Boolean(args.maxSizeInMB);
const isArgsMaxRows = (args) => Boolean(args.maxNumberOfRows);
const isArgsHeaders = (args) => Boolean(args.headers);
const isArgsHeader = (args) => Boolean(args.header);
const isArgsExtension = (args) => Boolean(args.extension);
const isArgsPattern = (args) => Boolean(args.pattern);
const isArgsMaxReplies = (args) => Boolean(args.maxRepliesNumber);
export const isCommonError = (error) => {
    if (error.message && typeof error.error === 'string')
        return true;
    return false;
};
export const useError = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const setErrorCallback = useCallback((reason) => {
        setErrorMessage(getErrorMessageFromReason(reason));
    }, []);
    const clearErrorCallback = useCallback(() => setErrorMessage(null), []);
    return [errorMessage, setErrorCallback, clearErrorCallback];
};
export const addErrorNamespace = (code) => `${ERRORS_TRANSLATION_NAMESPACE}:${code}`;
export const getErrorCodeFromReason = (reason) => {
    var _a, _b, _c, _d, _e;
    if (typeof reason === 'string')
        return reason;
    if (typeof reason !== 'object' || reason === null)
        return UNKNOWN_ERROR_MESSAGE_CODE;
    if (reason.code === CONNECTION_ABORTED_CODE)
        return CONNECTION_ABORTED_CODE;
    if (typeof ((_b = (_a = reason === null || reason === void 0 ? void 0 : reason.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === 'string')
        return reason.response.data.error;
    if (typeof ((_e = (_d = (_c = reason === null || reason === void 0 ? void 0 : reason.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.error) === null || _e === void 0 ? void 0 : _e.errorCode) === 'string')
        return reason.response.data.error.errorCode;
    return UNKNOWN_ERROR_MESSAGE_CODE;
};
//TODO remove parameter after caila error fix ZB-20236
export const getErrorMessageFromReason = (reason, translate, cailaError) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const translateFunc = translate || t;
    const errorCode = getErrorCodeFromReason(reason);
    let errorMessage = cailaError ? (_c = (_b = (_a = reason === null || reason === void 0 ? void 0 : reason.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.message : (_e = (_d = reason === null || reason === void 0 ? void 0 : reason.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.message;
    if (((_g = (_f = reason === null || reason === void 0 ? void 0 : reason.response) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.errors) && ((_j = (_h = reason === null || reason === void 0 ? void 0 : reason.response) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.errors.length) > 0 && !errorMessage) {
        if (!errorMessage) {
            errorMessage = '';
        }
        reason.response.data.errors.forEach((error) => {
            if (typeof error === 'string') {
                errorMessage += error + '</br>';
            }
        });
    }
    if (errorCode || errorMessage) {
        if (errorMessage === 'No message available' && isExistKey(addErrorNamespace(errorCode)))
            return translateFunc(addErrorNamespace(errorCode));
        if (errorMessage && errorCode === UNKNOWN_ERROR_MESSAGE_CODE)
            return errorMessage;
        if (isExistKey(addErrorNamespace(errorCode))) {
            try {
                //@ts-ignore
                return translateFunc.apply(this, [
                    addErrorNamespace(errorCode),
                    ...Object.values(getErrorArgsFromReason(reason)),
                ]);
            }
            catch (e) {
                return translateFunc(addErrorNamespace(errorCode));
            }
        }
        return errorMessage || errorCode || translateFunc(addErrorNamespace(UNKNOWN_ERROR_MESSAGE_CODE));
    }
    return translateFunc(addErrorNamespace(UNKNOWN_ERROR_MESSAGE_CODE));
};
export const getErrorArgsFromReason = (reason) => {
    var _a, _b, _c, _d, _e;
    if (!reason)
        return;
    return ((_c = (_b = (_a = reason.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) === null || _c === void 0 ? void 0 : _c.args) || ((_e = (_d = reason.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.args);
};
export const getNLUErrorFromResponse = (payload) => {
    let data = { error: '' };
    const { lastError } = payload.data;
    if (lastError) {
        try {
            data = typeof lastError === 'string' ? JSON.parse(lastError) : { error: lastError };
        }
        catch (e) {
            // @ts-ignore
            data = { error: e.toString() };
        }
    }
    return { response: { data } };
};
export const processErrorsArray = (errorsArr = []) => {
    const translate = t;
    const errorArgNames = ['settings', 'extSettingsAlgorithm', 'classifier', 'tokenizer', 'analyzer', 'id'];
    return errorsArr.map(error => isExistKey(`Errors:${error.errorCode}`)
        ? translate(`Errors:${error.errorCode}`, {
            error: error.args[Object.keys(error.args).find(key => errorArgNames.includes(key)) || ''],
        })
        : translate('caila set language error'));
};
const getMessageFromError = (args, errorCode) => {
    if (!args)
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode);
    if (isArgsPattern(args)) {
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.coordinates.row, args.coordinates.column, args.lineNumber, args.message);
    }
    if (isArgsMaxReplies(args)) {
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.maxRepliesNumber, args.coordinates.row);
    }
    if (isArgsCoords(args)) {
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.coordinates.row, args.coordinates.column);
    }
    if (isArgsCell(args)) {
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.cellValue);
    }
    if (isArgsSize(args))
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.maxSizeInMB);
    if (isArgsMaxRows(args))
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.maxNumberOfRows);
    if (isArgsHeaders(args))
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.headers);
    if (isArgsHeader(args))
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.header);
    if (isArgsExtension(args))
        return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode, args.extension);
    return t(ERRORS_TRANSLATION_NAMESPACE + ':' + errorCode);
};
export const getMessageTranslationFromImportError = (reason) => {
    if (!reason.response)
        return t('Unexpected error');
    const errorBody = reason.response.data;
    if (isCommonError(errorBody)) {
        return typeof errorBody.error === 'string' && isExistKey(errorBody.error) ? t(errorBody.error) : errorBody.message;
    }
    const { args, errorCode } = errorBody.error;
    if (errorBody.errors.length === 1) {
        return getMessageFromError(args, errorCode);
    }
    return errorBody.errors.map(error => getMessageFromError(error.args, error.errorCode)).join('\n\n');
};
export const getDeletedPhrasesMessage = (count, language) => {
    const intl = new Intl.PluralRules(language);
    const phrasesDeclination = intl.select(count);
    if (count === 1)
        return t('LogLabelingPage:deletedPhrasesInfo_one');
    return phrasesDeclination === 'one'
        ? t('LogLabelingPage:deletedPhrasesInfo_one--with-number', count, t(`LogLabelingPage:deletedPhrasesCount_${phrasesDeclination}`))
        : t('LogLabelingPage:deletedPhrasesInfo_other', count, t(`LogLabelingPage:deletedPhrasesCount_${phrasesDeclination}`));
};
export const assignValidationErrorToReply = (errorData, question) => {
    if (!errorData || !errorData.length)
        return question;
    errorData.forEach(answer => {
        const replyToUpdateIndex = question.replies.findIndex(reply => reply.text === answer.origin);
        if (question.replies[replyToUpdateIndex]) {
            question.replies[replyToUpdateIndex].error = 'validation';
        }
    });
    return question;
};
export const assignValidationErrorToPhrase = (errorData, phrases) => {
    if (!errorData || !errorData.length)
        return phrases;
    const newPhrases = [...phrases];
    errorData.forEach(error => {
        const phraseToUpdateIndex = newPhrases.findIndex(phrase => phrase.text === error.origin);
        if (newPhrases[phraseToUpdateIndex]) {
            newPhrases[phraseToUpdateIndex].error = 'validation';
        }
    });
    return newPhrases;
};
/**
 * @param {number} deletedCount число удаленных фраз
 * @param {number} updatedCount число измененных фраз
 * @param {string} language язык для склонения
 *
 * Конструктор фразы о результатах предобработки фраз.
 * Если оба параметра - 0, то просто строка-уведомление о завершении предобработки.
 *
 * Если есть оба параметра, то строка вида
 *
 * Предобработка завершена: X фраз изменены, Y — удалены.
 * Preprocessing completed: X phrases changed, Y phrases deleted.
 *
 * Если есть что то одно - то часть строки выше
 *
 * */
export const getProcessedPhrasesMessage = (deletedCount, updatedCount, language) => {
    if (!deletedCount && !updatedCount)
        return t('LogLabelingPage:cleanDataCompleted') + '.';
    const intl = new Intl.PluralRules(language);
    const deletedDeclination = intl.select(deletedCount);
    const changedDeclination = intl.select(updatedCount);
    if (deletedCount && !updatedCount) {
        return `${t('LogLabelingPage:cleanDataCompleted')}: ${deletedCount} ${t(`LogLabelingPage:deletedPhrasesCount_${deletedDeclination}`)} ${t(`LogLabelingPage:deletedCount_${deletedDeclination}`)}.`;
    }
    if (updatedCount && !deletedCount) {
        return `${t('LogLabelingPage:cleanDataCompleted')}: ${updatedCount} ${t(`LogLabelingPage:deletedPhrasesCount_${changedDeclination}`)} ${t(`LogLabelingPage:changedCount_${changedDeclination}`)}.`;
    }
    if (language.toLowerCase() === 'ru') {
        return `${t('LogLabelingPage:cleanDataCompleted')}: ${updatedCount} ${t(`LogLabelingPage:deletedPhrasesCount_${changedDeclination}`)} ${t(`LogLabelingPage:changedCount_${changedDeclination}`)}, ${deletedCount} — ${t(`LogLabelingPage:deletedCount_${deletedDeclination}`)}`;
    }
    return `${t('LogLabelingPage:cleanDataCompleted')}: ${updatedCount} ${t(`LogLabelingPage:deletedPhrasesCount_${changedDeclination}`)} ${t(`LogLabelingPage:changedCount_${changedDeclination}`)}, ${deletedCount} ${t(`LogLabelingPage:deletedPhrasesCount_${changedDeclination}`)} ${t(`LogLabelingPage:deletedCount_${deletedDeclination}`)}`;
};
