import { useCallback, useRef, useState } from 'react';
function generateId() {
    return 'id_' + (Math.random() + 1).toString(36).substring(7);
}
export function useId() {
    return useRef(generateId()).current;
}
export function useIdState() {
    const [id, setId] = useState('');
    const generateStateId = useCallback(() => {
        setId(generateId());
    }, []);
    return [id, generateStateId];
}
