import React, { Component, Fragment } from 'react';
import loadScript from 'simple-load-script';
import TagManager from 'react-gtm-module';
import routes from './routes';
import { bindActionCreators } from 'redux';
import { getAppConfig, getCloudAppOptions } from './actions/appConfig.actions';
import { connect } from 'react-redux';
import localize from './localization';
import { getUserLanguage, isDev } from './pipes/functions';
import { getGoogleTag } from './utils';
import { initLastExternalReferer } from './utils/lastExternalReferer';
import { editorFileCacheService } from './service_workers/editorFilesCacheInitializator';
import 'flag-icon-css/css/flag-icon.css';
import { registerPreloadedIcons } from '@just-ai/just-ui/dist/Icon';
import { Spinner } from '@just-ai/just-ui';
import { isEuroInstance } from './isAccessFunction';
import { AppLogger } from '@just-ai/logger';
import { cloudDomainsService } from './services/CloudDomainsService';
import { SyncFrontendVersionService } from './services/SyncFrontendVersionService';

registerPreloadedIcons([
  'farWifiSlash',
  'faTimes',
  'faCheckSquare',
  'faCheckCircle',
  'farTimesCircle',
  'faInfoCircle',
  'faExclamationCircle',
  'faExclamationTriangle',
  'faInfoCircle',
  'farText',
  'faAt',
  'faCloudOffline',
  'farSyncAlt',
]);

export const syncFrontendVersionService = isDev() ? null : new SyncFrontendVersionService();

class App extends Component {
  state = {
    load: false,
  };

  componentDidMount() {
    const { fetching, fetched } = this.props;

    editorFileCacheService.start();
    initLastExternalReferer();

    const setAppMeta = botadminData => {
      document.title = Boolean(botadminData)
        ? `${botadminData.companyName} ${botadminData.productName}`
        : 'Just-AI bot admin';

      if (Boolean(botadminData) && Boolean(botadminData.favicon) && botadminData.favicon.length > 0) {
        let link = document.querySelector("link[rel*='icon']");
        if (!Boolean(link)) {
          link = document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'shortcut icon';
          document.getElementsByTagName('head')[0].appendChild(link);
        }
        if (isEuroInstance()) {
          link.href = '/favicon_tovie.ico';
        } else {
          link.href = botadminData.favicon;
        }
      }
    };

    const loadAppConfig = async () => {
      if (!fetching && !fetched) {
        const cloudOptions = await this.props
          .getCloudAppOptions()
          .catch(AppLogger.createErrorHandler('getCloudAppOptions'));
        if (!cloudOptions) return;
        cloudDomainsService.setDomains(cloudOptions.value.data.domains);
        const response = await this.props.getAppConfig().catch(AppLogger.createErrorHandler('loadAppConfig'));
        if (!response) return;
        const gaEnabled = response?.value?.data?.gaEnabled;
        const experrtoEnabled = response?.value?.data?.experrtoEnabled;

        localize.setAppConfigVariables(response.value.data.botadmin);
        localize.setConfigVariables({
          botadmin: {
            helpUrl: response.value.data.botadmin.helpUrl,
            productName: {
              eng: response.value.data.botadmin.productName,
              ru: response.value.data.botadmin.productName,
            },
            companyName: {
              ru: response.value.data.botadmin.companyName,
              eng: response.value.data.botadmin.companyName,
            },
          },
          nluProductName: response.value.data.botadmin.euroInstance
            ? { ru: 'Tovie NLP', eng: 'Tovie NLP' }
            : { ru: 'NLU', eng: 'NLU' },
        });
        if (experrtoEnabled && this.props.language === 'ru') {
          loadScript({
            url: 'https://cdn.experrto.io/client/experrto.js',
            attrs: {
              async: true,
            },
          })
            .then(() => {
              try {
                if (window.Experrto) {
                  window.Experrto.identify('0b312e318cd2a612c8f3ec3d91d8bd67ffa764e1');
                }
              } catch (e) {
                AppLogger.error({
                  message: 'Experrto.identify error',
                  exception: e,
                });
              }
            })
            .catch(experrtoLoadingError =>
              AppLogger.error({
                message: 'Experrto loading error',
                exception: experrtoLoadingError,
              })
            );
        }
        if (gaEnabled) {
          const tagManagerArgs = {
            gtmId: getGoogleTag(),
          };
          TagManager.initialize(tagManagerArgs);
        }
        await localize.setLocale(
          getUserLanguage(response.value.data.botadmin && response.value.data.botadmin.userCountryIsoCode)
        );
        await setAppMeta(response.value.data.botadmin);
      } else {
        await localize.setLocale(getUserLanguage());
      }
    };

    syncFrontendVersionService?.startWatch(window.env?.BUILD_NUMBER);

    loadAppConfig()
      .then(() => this.setState({ load: true }))
      .catch(AppLogger.createErrorHandler('loadAppConfig'));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const currentLang = this.props.language;
    const prevLang = prevProps.language;

    if (currentLang !== prevLang) {
      localize.setLocale(currentLang);
    }
  }

  componentWillUnmount() {
    syncFrontendVersionService?.stopWatch();
  }

  render() {
    const { load } = this.state;
    return load ? <Fragment>{routes}</Fragment> : <Spinner />;
  }
}

const mapDispatchToProps = dispatch => ({
  getAppConfig: bindActionCreators(getAppConfig, dispatch),
  getCloudAppOptions: bindActionCreators(getCloudAppOptions, dispatch),
});

function mapStateToProps(state) {
  return {
    fetching: state.AppConfigReducer.fetching,
    fetched: state.AppConfigReducer.fetched,
    language: state.CurrentUserReducer.language,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
