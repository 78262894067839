import { useEffect } from 'react';
export function usePermissionAccessChange(permission, cb) {
    useEffect(() => {
        let permStatus;
        const onChangeStatus = () => permStatus && cb(permStatus);
        // @ts-ignore
        navigator.permissions.query({ name: permission }).then(permissionStatus => {
            permStatus = permissionStatus;
            permStatus.addEventListener('change', onChangeStatus);
        });
        return () => permStatus === null || permStatus === void 0 ? void 0 : permStatus.removeEventListener('change', onChangeStatus);
    }, [cb, permission]);
}
