import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';
import { createSlice, current, PayloadAction, isRejected, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  createState,
  getCustomTags,
  getGraph,
  getGraphLocators,
  getJGraphVisuals,
  updateJGraphVisuals,
  updateState,
} from './JGraphAsyncActions';

import {
  dtoToGraph,
  findScreenByPath,
  findScreenByPathId,
  FromStateConnectionsStore,
  getAllInnerStates,
  getConnectionsFromBlocks,
  getConnectorPropsFromName,
  getValidKonvaName,
  rebuildGraph,
} from './Graph';
import { CustomTagData, JStateLocator } from '../../modules/Editor/api/client';
import { EditMenuBlock, JGraphStateData, JStateWithId, TConnector, JGraphTheme } from './types';
import { TActivationParameters, TagNames, TJBlock, TTagParameters } from '../../modules/JGraph/utils/types';
import JGraphStorageService, { JGraphStagePathSettings } from '../../modules/JGraph/services/JGraphStorageService';
import {
  cleanFromStateMapConnections,
  createBlockTagParameters,
  createNewState,
  fillJBlocksPathByLine,
  getParentPaths,
  getPositionBeforeToStateBlocks,
  makeFromStateConnections,
  moveBlocksFromDescendingTags,
  normalizeSticker,
  recursiveCleanUpDebugData,
  restoreStateToStateBlocksInPlace,
  startsWithCapital,
  tagParametersToObj,
} from './utils';
import { AutoLayoutType, BlocksPositions } from '../../modules/JGraph/hooks/useAutoplacement';
import { EditMenuBlock$, StateCollapseSubject$ } from '../../modules/JGraph/view/StateScreen.hooks';
import { LabelingService } from 'modules/JGraph/view/LabelingToolMenu/LabelingService';
import { CustomTagsStore$ } from './customTags.store';
import localize from 'localization';
import { revertCreateState } from 'reducers/JGraph.reducer/AsyncActions/createState';
import { revertDeleteState } from 'reducers/JGraph.reducer/AsyncActions/deleteState';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';
import { AddNewBlockPayload } from 'reducers/JGraph.reducer/AsyncActions/addNewBlockInReduxAndApi';
import { MigrateBlockPayload } from './AsyncActions/migrateTextToRandom';
import { debugDataState$, JWidgetMessageLocator } from '../../modules/JGraph/pipes/debugDataPipe';
import { scrollToTargetGlobal$ } from '../../modules/JGraph/utils/stageUtils';
import { RerenderScreensEvent$ } from 'modules/JGraph/contexts/StageObservablesProvider';
import { JGraphReduxActions$ } from 'modules/JGraph/components/JGraphStatusIndicator';
import { JGraphReduxActionEventStatus } from 'modules/JGraph/components/JGraphStatusIndicator/types';
import { guideTourEvent$ } from 'modules/JGraph/view/JGraphGuideTour/guideTourEvents';
import { FloatingConnectorMenu$ } from '../../modules/JGraph/view/FloatingConnectorMenu';
import { MoveStateInGroup$ } from '../../modules/JGraph/view/MoveStateInGroup';
import { LabelingToolMenu$ } from '../../modules/JGraph/view/LabelingToolMenu';
import { initialNewConnectorState, newConnectorSubject$ } from 'modules/JGraph/hooks';
import { JGraphVisuals, JGraphVisualsData, JGraphStickersInfo } from '@just-ai/api/dist/generated/Editorbe';
import { hiddenConnections$ } from '../../modules/JGraph/hooks/hiddenConnections';
import { StickerInfo } from 'modules/JGraph/view/Sticker/types';
import { Vector2D } from 'modules/JGraph/utils/2DVector';
import { rafAppScheduler } from 'utils/sheduler/buildScheduler';
import { themePositionBatch$ } from 'modules/JGraph/view/ThemesStage/ThemeCard';
import deleteTheme from './AsyncActions/deleteTheme';

export type JGraphAddingMenuType = 'actions' | 'reactions';

const menuSizes: { [key in JGraphAddingMenuType]: { width: number; height: number } } = {
  actions: {
    width: 250,
    height: 372,
  },
  reactions: {
    width: 284,
    height: 150,
  },
};

export type JGLoadError = {
  code: string;
  message: string;
  args?: object;
};
export type JGraphState = {
  isEditModeEnable: boolean;
  error?: JGLoadError;
  isThemesOpen: boolean;
  selectedTheme?: JGraphTheme;
  loadingGraph: boolean;
  entrypoint: {
    filename: string;
  };
  graph: JGraphStateData;
  stickers: StickerInfo[];
  stickersVersion: {
    lastModified: number;
    version: number;
  };

  loadingCustomTags: boolean;
  customTags: CustomTagData[];

  currentVisualStageSettings: JGraphStagePathSettings;

  //плавающее меню и его события
  isAddingMenuOpen: boolean;
  addingMenuScreenId: string;
  actionToAdd: string;
  actionAddingMenuPosition: { x: number; y: number };
  addingMenuOpenType?: JGraphAddingMenuType;
  //--end

  stateNameInputPosition: { x: number; y: number };
  stateNameInputValue: string;
  screenCreationMenu: {
    open: boolean;
    screenPosition: Vector2d;
    pointerPosition: Vector2d;
    from?: string;
    toPath?: string;
    fromPath?: string;
    parentStatePath?: string;
    parentThemeValue?: string;
    isOpenedByButton?: boolean;
    fixedParentPath?: boolean;
  };
  themeCreationMenu: {
    open: boolean;
    themePosition: Vector2d;
    pointerPosition: Vector2d;
    isOpenedByButton?: boolean;
  };
  contextMenu: {
    open: boolean;
    screenPosition: Vector2d;
    pointerPosition: Vector2d;
    statePath?: string;
    themePath?: string;
    statePosition?: Vector2d;
  };
  removedConnectorOnNewBlock: TConnector | null;
  editMenuBlock?: EditMenuBlock;
  tagLocators: JStateLocator[];
  tagLocatorsLoaded: boolean;
  locatorsMap: Record<string, Record<number, number[]>>;
  previousHighLightStates: string[];
  visuals: {
    loading: boolean;
    visualsData: JGraphVisuals;
  };
};

const initialState: JGraphState = {
  isEditModeEnable: true,
  isThemesOpen: true,
  loadingGraph: true,
  graph: new JGraphStateData(),
  entrypoint: {
    filename: '/src/main.sc',
  },
  stickers: [],
  stickersVersion: {
    lastModified: 0,
    version: 0,
  },
  loadingCustomTags: true,
  customTags: [],
  currentVisualStageSettings: new JGraphStagePathSettings(),
  isAddingMenuOpen: false,
  addingMenuOpenType: undefined,
  addingMenuScreenId: '',
  actionToAdd: '',
  actionAddingMenuPosition: {
    x: 0,
    y: 0,
  },
  stateNameInputPosition: {
    x: 0,
    y: 0,
  },
  stateNameInputValue: '',

  editMenuBlock: undefined,
  screenCreationMenu: {
    open: false,
    screenPosition: { x: 0, y: 0 },
    pointerPosition: { x: 0, y: 0 },
  },
  themeCreationMenu: {
    open: false,
    themePosition: { x: 0, y: 0 },
    pointerPosition: { x: 0, y: 0 },
  },
  contextMenu: {
    open: false,
    screenPosition: { x: 0, y: 0 },
    pointerPosition: { x: 0, y: 0 },
  },
  removedConnectorOnNewBlock: null,
  tagLocators: [],
  tagLocatorsLoaded: false,
  locatorsMap: {},
  previousHighLightStates: [],
  visuals: {
    loading: true,
    visualsData: {
      version: 0,
      lastModified: 0,
      data: {
        connectors: {
          from: [],
          to: [],
        },
        collapsedStates: [],
      } as JGraphVisualsData,
    },
  },
};

class JGraphLocalStorage {
  public store = new JGraphStorageService('');
  setPSN = (projectShortName: string) => {
    this.store = new JGraphStorageService(projectShortName);
  };
}

export const JGLS = new JGraphLocalStorage();

export const JGraphSlice = createSlice({
  name: 'JGraphStore',
  initialState,
  reducers: {
    cleanUnsaved: state => {
      state.graph.blocks = state.graph.blocks.filter(screen => !screen.isUnsaved);
    },
    setBlockPositions: (state, action: PayloadAction<BlocksPositions>) => {
      for (let [pathId, position] of Object.entries(action.payload)) {
        let screen = findScreenByPathId(pathId, state.graph.blocks);
        if (screen) {
          screen.x = position.x;
          screen.y = position.y;
        }
      }
    },
    saveBlockMovement: (state, action: PayloadAction<{ newPositions: Vector2d; blockStatePath: string }>) => {
      const { newPositions, blockStatePath } = action.payload;
      const blocks = [...state.graph.blocks];
      let block = findScreenByPathId(getValidKonvaName(blockStatePath), blocks);
      if (block) {
        block.x = newPositions.x;
        block.y = newPositions.y;
      }
    },
    changeStateName: (state, action: PayloadAction<string>) => {
      const blocks = [...state.graph.blocks];
      let block = findScreenByPathId(state.stateNameInputValue, blocks);

      if (block) {
        block.path = action.payload;
        state.graph.blocks = blocks;
        state.stateNameInputPosition = initialState.stateNameInputPosition;
        state.stateNameInputValue = initialState.stateNameInputValue;
      }
    },
    closeStateNameEditField: state => {
      state.stateNameInputPosition = initialState.stateNameInputPosition;
      state.stateNameInputValue = initialState.stateNameInputValue;
    },
    showStateNameEditField: (
      state,
      action: PayloadAction<{ event: Konva.KonvaEventObject<MouseEvent>; stateName: string }>
    ) => {
      const { event } = action.payload;
      const textPosition = event.currentTarget.getAbsolutePosition();
      state.stateNameInputValue = action.payload.stateName;
      state.stateNameInputPosition = {
        x: textPosition.x - 4,
        y: textPosition.y - 2,
      };
    },
    toggleAddingActionsMenu: (
      state,
      action: PayloadAction<{
        event: Konva.KonvaEventObject<MouseEvent> | boolean;
        screenId?: string;
      }>
    ) => {
      const { event, screenId } = action.payload;
      if (typeof event === 'boolean') {
        if (state.isAddingMenuOpen === event) return state;
        state.isAddingMenuOpen = event;
        state.addingMenuScreenId = '';
        state.actionToAdd = '';
      } else {
        const stage = event.currentTarget?.getStage();
        const scale = stage?.getAbsoluteScale();
        if (scale && event.currentTarget.attrs.button && event.currentTarget.attrs.actionType) {
          const targetPosition = event.currentTarget.getAbsolutePosition();
          const offsetFromButton = (24 + 4) * scale.x;
          targetPosition.x += offsetFromButton;
          const menuSize = menuSizes[event.currentTarget.attrs.actionType as JGraphAddingMenuType];
          const menuPosition = {
            x: targetPosition.x,
            y: targetPosition.y,
          }; // right
          if (menuPosition.x + menuSize.width > (stage?.width() || 0) + (stage?.offsetX() || 0)) {
            menuPosition.x -= offsetFromButton + menuSize.width + 4 * scale.x;
            // left
          }

          if (menuPosition.y + menuSize.height > (stage?.height() || 0) + (stage?.offsetY() || 0)) {
            menuPosition.y -= menuSize.height - 24;
            // top
          }

          state.addingMenuOpenType = event.currentTarget.attrs.actionType;
          state.actionAddingMenuPosition = menuPosition;
        }

        state.isAddingMenuOpen = !!event.currentTarget?.attrs.button;
        state.addingMenuScreenId = screenId || '';
      }
    },
    onAddingMenuElementClick: (state, action: PayloadAction<string>) => {
      state.actionToAdd = action.payload;
      const foundScreen = findScreenByPathId(state.addingMenuScreenId, state.graph.blocks);
      if (foundScreen) {
        state.isAddingMenuOpen = false;
        state.addingMenuScreenId = '';
        state.editMenuBlock = {
          screen: foundScreen,
        };
        EditMenuBlock$.next({
          screen: current(foundScreen),
        });
      }
    },
    cleanUpStateOnAddingMenu: state => {
      state.actionToAdd = '';
    },
    openAddingMenu: (state, action: PayloadAction<JGraphStagePathSettings>) => {
      state.currentVisualStageSettings = action.payload;
    },
    setJGraphInEditMode: (state, action: PayloadAction<boolean>) => {
      state.isEditModeEnable = action.payload;
      state.editMenuBlock = undefined;
      state.isAddingMenuOpen = false;
      JGraphSlice.caseReducers.closeScreenCreationMenu(state);
      JGraphSlice.caseReducers.closeThemeCreationMenu(state);
      JGraphSlice.caseReducers.closeContextMenu(state);
      setTimeout(() => {
        EditMenuBlock$.next(undefined);
        MoveStateInGroup$.next(null);
        LabelingToolMenu$.next(null);
        FloatingConnectorMenu$.next({ connector: undefined });
        newConnectorSubject$.next(initialNewConnectorState);
      }, 500);
    },
    setEditMenuBlock: (state, action: PayloadAction<Partial<EditMenuBlock> | undefined>) => {
      if (!state.isEditModeEnable) return;
      const { screen } = state.editMenuBlock || {};
      if (!screen && !action.payload) return state;
      if (screen && screen.isUnsaved && screen.path !== action.payload?.screen?.path) {
        state.graph.blocks = [...state.graph.blocks].filter(state => !state.isUnsaved);
        state.editMenuBlock = undefined;
      } else {
        if (action.payload) {
          if (state.editMenuBlock) {
            state.editMenuBlock = {
              ...current(state.editMenuBlock),
              ...action.payload,
            };
          } else {
            //@ts-ignore
            state.editMenuBlock = action.payload;
          }
        } else {
          state.editMenuBlock = action.payload;
        }
      }
      state.isAddingMenuOpen = false;
      if (state.screenCreationMenu.open) {
        state.screenCreationMenu.open = false;
        state.screenCreationMenu.from = undefined;
        state.screenCreationMenu.toPath = undefined;
        state.screenCreationMenu.fromPath = undefined;
        state.screenCreationMenu.isOpenedByButton = undefined;
        state.screenCreationMenu.fixedParentPath = undefined;
        state.screenCreationMenu.parentThemeValue = undefined;
      }
      if (state.contextMenu.open) {
        state.contextMenu.open = false;
      }
      const newEditMenuBlock = !!state.editMenuBlock ? { ...state.editMenuBlock } : undefined;

      EditMenuBlock$.next(newEditMenuBlock);
    },
    saveScreen: (state, action: PayloadAction<EditMenuBlock['screen']>) => {
      let foundEditBlock = findScreenByPathId(action.payload.pathId, state.graph.blocks);
      if (foundEditBlock) {
        foundEditBlock.blocks = action.payload.blocks;
        foundEditBlock.id = uuid();

        const { fromStateTransitions } = getConnectionsFromBlocks(state.graph.blocks);
        state.graph.fromStateTransitions = fromStateTransitions;

        Object.keys(fromStateTransitions || {}).forEach(statePath => {
          const fromStateScreen = findScreenByPath(statePath, state.graph.blocks);
          if (!fromStateScreen) return;
          fromStateScreen.canRender = true;
          fromStateScreen.blocks = [...fromStateScreen.blocks];
        });

        if (state.editMenuBlock) {
          if (state.editMenuBlock.path && ScreenBlockPath.isPathRecursive(state.editMenuBlock.path)) {
            const [, mainIfIndex] = ScreenBlockPath.getMainIfBlockAndIndex(
              state.editMenuBlock!.path,
              state.editMenuBlock.jBlockIndex,
              foundEditBlock.blocks
            );
            const mainIfPath = `${mainIfIndex}_if_`;
            state.editMenuBlock = {
              screen: foundEditBlock,
              jBlockIndex: undefined,
              path: mainIfPath,
            };
            EditMenuBlock$.next({
              screen: { ...foundEditBlock },
              jBlockIndex: undefined,
              path: mainIfPath,
            });
          } else {
            state.editMenuBlock = {
              screen: foundEditBlock,
            };
            EditMenuBlock$.next({
              screen: { ...foundEditBlock },
            });
          }
        }
        guideTourEvent$.next('SaveScreen');
      }
    },
    addNewBlockInState: (state, action: PayloadAction<AddNewBlockPayload>) => {
      const { tagName, prefix, insertPosition, withoutSetEdit } = action.payload;
      let newBlocks = [...state.graph.blocks];
      let position = 0;
      if (state.editMenuBlock?.screen) {
        let foundEditScreen = findScreenByPathId(state.editMenuBlock!.screen.pathId, newBlocks);
        if (!foundEditScreen) return state;

        const isPathRecursive = prefix && ScreenBlockPath.isPathRecursive(prefix);
        if (isPathRecursive) {
          let possibleBlockEditPath;
          if (ScreenBlockPath.isPathContainsIfElse(prefix)) {
            possibleBlockEditPath = ScreenBlockPath.getCurrentIfBlock(foundEditScreen?.blocks, prefix);
          } else {
            possibleBlockEditPath = ScreenBlockPath.getBlockByPath(foundEditScreen?.blocks, prefix);
          }
          if (possibleBlockEditPath) {
            position = insertPosition !== undefined ? insertPosition : possibleBlockEditPath.jblocks.length;
            possibleBlockEditPath.jblocks.splice(position, 0, {
              tagName: tagName,
              tagValue: '',
              tagParameters: createBlockTagParameters(tagName),
              jblocks: [],
            });
          }
        } else {
          position =
            insertPosition !== undefined ? insertPosition : getPositionBeforeToStateBlocks(foundEditScreen.blocks);
          foundEditScreen.blocks.splice(position, 0, {
            tagName: tagName,
            tagValue: '',
            tagParameters: createBlockTagParameters(tagName),
            jblocks: [],
          });
        }
        foundEditScreen.id = uuid();
        if (withoutSetEdit) {
          state.editMenuBlock.screen = foundEditScreen;
          return;
        }
        state.editMenuBlock = {
          screen: foundEditScreen,
          jBlockIndex: position,
          path: prefix,
        };
        EditMenuBlock$.next({
          screen: current(foundEditScreen),
          jBlockIndex: position,
          path: prefix,
        });
        guideTourEvent$.next(`AddedNewBlock::${action.payload.tagName}`);
      }
    },
    migrateTextToRandom: (state, action: PayloadAction<MigrateBlockPayload>) => {
      if (!state.editMenuBlock?.screen) return state;
      let newBlocks = [...state.graph.blocks];
      let foundEditScreen = findScreenByPathId(state.editMenuBlock!.screen.pathId, newBlocks);
      if (!foundEditScreen) return state;

      const { block, prefix, blockIndex } = action.payload;
      const possibleBlockEditPath = ScreenBlockPath.getBlockByPathAndIndex(foundEditScreen?.blocks, prefix, blockIndex);
      if (possibleBlockEditPath) {
        possibleBlockEditPath.jblocks.push(block);
      }

      foundEditScreen.id = uuid();
      state.editMenuBlock = {
        screen: foundEditScreen,
        jBlockIndex: blockIndex,
        path: prefix,
      };
      EditMenuBlock$.next({
        screen: current(foundEditScreen),
        jBlockIndex: blockIndex,
        path: prefix,
      });
    },
    addNewState: (
      state,
      action: PayloadAction<{
        screenPath: string;
        setEdit?: boolean;
        addingBlock?: TJBlock['tagName'];
        parentStatePath?: string;
        theme?: string;
        blocks?: TJBlock[];
        position?: { x: number; y: number };
      }>
    ) => {
      const {
        screenPath,
        parentStatePath,
        addingBlock,
        blocks = [],
        position,
        theme,
        setEdit = false,
      } = action.payload;

      const foundParentBlock = parentStatePath ? findScreenByPath(parentStatePath, state.graph.blocks) : undefined;
      const addingBlocks = addingBlock ? [addingBlock] : [];

      let screenPosition = position;
      if (!screenPosition) {
        screenPosition =
          JSON.stringify(state.screenCreationMenu.screenPosition) === JSON.stringify({ x: 0, y: 0 }) &&
          state.editMenuBlock
            ? { x: state.editMenuBlock.screen.x + 360, y: state.editMenuBlock.screen.y }
            : state.screenCreationMenu.screenPosition;
      }

      const themeInfo = state.graph.themes.find(themeInState => themeInState.value === theme);

      const newState = createNewState(
        screenPath,
        addingBlocks,
        screenPosition,
        foundParentBlock,
        state.entrypoint.filename,
        themeInfo
      );

      newState.blocks.push(...blocks);
      if (foundParentBlock) {
        if (!foundParentBlock.states) {
          foundParentBlock.states = [];
        }
        foundParentBlock.states.push(newState);
      } else {
        let newBlocks = [...state.graph.blocks];
        newBlocks.push(newState);
        state.graph.blocks = newBlocks;
      }

      if (state.screenCreationMenu.from) {
        let foundConnection: TConnector | undefined = undefined;
        /*const sub = ConnectorsStore$.subscribe(storeConnectors => {
          let storeFoundConnection = storeConnectors.find(
            connection => connection.from === state.screenCreationMenu.from
          );
          if (storeFoundConnection) {
            foundConnection = { ...storeFoundConnection };
          }
        });
        sub.unsubscribe();*/

        if (foundConnection) {
          state.removedConnectorOnNewBlock = foundConnection;
        }
      }
      if (setEdit) {
        state.editMenuBlock = {
          screen: newState,
          jBlockIndex: addingBlock ? 0 : undefined,
        };
        EditMenuBlock$.next({
          screen: newState,
          jBlockIndex: addingBlock ? 0 : undefined,
        });
      }

      state.screenCreationMenu.open = false;
    },
    restoreState: (state, action: PayloadAction<{ screen: JStateWithId; parentPath?: string }>) => {
      const prevState = action.payload.screen;
      prevState.canRender = true;
      const { parentStatePath } = getParentPaths(prevState.path, prevState.theme);
      const parentPath = action.payload.parentPath || parentStatePath;

      let foundParentBlock = parentPath ? findScreenByPath(parentPath, state.graph.blocks) : undefined;

      if (foundParentBlock) {
        if (!foundParentBlock.states) foundParentBlock.states = [];
        restoreStateToStateBlocksInPlace(foundParentBlock.states, prevState);
      } else {
        let newBlocks = [...state.graph.blocks];
        restoreStateToStateBlocksInPlace(newBlocks, prevState);
        state.graph.blocks = newBlocks;
      }
      if (state.editMenuBlock?.screen.pathId === prevState.pathId) {
        state.editMenuBlock.screen = prevState;
      }
      let foundBlockFrom = findScreenByPathId(prevState.pathId, state.graph.blocks);
      if (!foundBlockFrom) return;
      makeFromStateConnections(foundBlockFrom, state.graph);
    },
    makeNewConnector: (
      state,
      action: PayloadAction<{
        from: string;
        to: string;
      }>
    ) => {
      const { from, to } = action.payload;
      const fromParams = getConnectorPropsFromName(from);
      let foundBlockFrom = findScreenByPathId(fromParams.blockPathId, state.graph.blocks);
      let toBlockPath = findScreenByPathId(to, state.graph.blocks)?.path;
      if (!foundBlockFrom || !toBlockPath) return;
      let block = foundBlockFrom.blocks[fromParams.screenJBlockIterator];

      if (ScreenBlockPath.isPathRecursive(fromParams.tagName)) {
        const foundedBlock = ScreenBlockPath.getBlockByPath(
          foundBlockFrom.blocks,
          fromParams.tagName,
          fromParams.screenJBlockIterator
        );
        if (foundedBlock) {
          block = foundedBlock;
        }
      }

      switch (block.tagName) {
        case TagNames.inlineButtons:
        case TagNames.buttons: {
          (block as TJBlock<TagNames.buttons>).tagParameters[fromParams.indexInTagName].transition = toBlockPath;
          break;
        }
        case TagNames.HttpRequest:
        case TagNames.Email: {
          const newTagParameters = (block as TJBlock<TagNames.Email>).tagParameters;
          const foundOkStateOrErrorState = newTagParameters.find(tagParam => {
            if (fromParams.indexInTagName === 0) {
              return tagParam.name === 'okState';
            }
            return tagParam.name === 'errorState';
          });
          //TODO check if okState or errorState
          if (foundOkStateOrErrorState) {
            foundOkStateOrErrorState.value = toBlockPath;
          }
          break;
        }
        case TagNames.q:
        case TagNames.event:
        case TagNames.intent:
          let obj: Record<string, TTagParameters<string, any>> = {};
          block.tagParameters.forEach(tagParam => {
            obj[tagParam.name] = tagParam;
          });
          obj.toState.value = toBlockPath;
          break;
        default: {
          if (state.customTags.map(tag => tag.tagName).includes(block.tagName)) {
            const descriptor = state.customTags.find(({ tagName }) => tagName === block.tagName);
            if (descriptor && descriptor.parameters) {
              const onlyStatesFromDescriptorIndex = descriptor.parameters.filter(param => param.type === 'state');
              const name = onlyStatesFromDescriptorIndex[fromParams.indexInTagName].name;

              if (!name) break;
              const tagParams = tagParametersToObj(block.tagParameters);
              tagParams[name].value = toBlockPath;
            }
            break;
          }
          (block as TJBlock<TagNames.go>).tagValue = toBlockPath;
          break;
        }
      }

      if (state.editMenuBlock?.screen.pathId === foundBlockFrom.pathId) {
        state.editMenuBlock.screen = foundBlockFrom;
      }
      makeFromStateConnections(foundBlockFrom, state.graph);
    },
    deleteConnection: (state, action: PayloadAction<TConnector>) => {
      const connector = action.payload;
      const fromParams = getConnectorPropsFromName(connector.from);
      let fromBlock = findScreenByPathId(connector.fromNode, state.graph.blocks);
      let toBlock = findScreenByPathId(connector.to || '', state.graph.blocks);
      if (fromParams.isFromState) {
        if (toBlock) {
          toBlock.blocks.forEach((block, index) => {
            if (block.tagName === fromParams.tagName && index === fromParams.screenJBlockIterator) {
              // @ts-ignore
              const tagParam = (block.tagParameters || []).find((param: TTagParameters) => {
                return param.name === 'fromState' && param.value === connector.fromNodeOriginalPath;
              });
              if (tagParam) {
                tagParam.value = null;
              }
            }
          });
          toBlock.blocks = [...toBlock.blocks];
        }
      } else {
        if (fromBlock) {
          let block = fromBlock.blocks[fromParams.screenJBlockIterator];

          if (ScreenBlockPath.isPathRecursive(fromParams.tagName)) {
            const foundedBlock = ScreenBlockPath.getBlockByPath(
              fromBlock.blocks,
              fromParams.tagName,
              fromParams.screenJBlockIterator
            );
            if (foundedBlock) {
              block = foundedBlock;
            }
          }

          switch (connector.tagName) {
            case TagNames.inlineButtons:
            case TagNames.buttons: {
              (block as TJBlock<TagNames.buttons>).tagParameters[fromParams.indexInTagName].transition = '';
              break;
            }
            case TagNames.HttpRequest:
            case TagNames.Email: {
              const newTagParameters = (block as TJBlock<TagNames.Email>).tagParameters;
              const foundOkStateOrErrorState = newTagParameters.find(tagParam => {
                if (fromParams.indexInTagName === 0) {
                  return tagParam.name === 'okState';
                }
                return tagParam.name === 'errorState';
              });
              if (foundOkStateOrErrorState) {
                foundOkStateOrErrorState.value = '';
              }
              break;
            }
            case TagNames.q:
            case TagNames.event:
            case TagNames.intent:
              let obj: Record<string, TTagParameters<string, any>> = {};
              block.tagParameters.forEach(tagParam => {
                obj[tagParam.name] = tagParam;
              });
              obj.toState.value = './';
              break;
            default: {
              if (state.customTags.map(tag => tag.tagName).includes(block.tagName)) {
                const descriptor = state.customTags.find(({ tagName }) => tagName === block.tagName);
                if (descriptor && descriptor.parameters) {
                  const onlyStatesFromDescriptorIndex = descriptor.parameters.filter(param => param.type === 'state');
                  const name = onlyStatesFromDescriptorIndex[fromParams.indexInTagName].name;
                  if (!name) break;
                  const tagParams = tagParametersToObj(block.tagParameters);
                  tagParams[name].value = '';
                }
                break;
              }
              (block as TJBlock<TagNames.go>).tagValue = '';
              break;
            }
          }
        }
      }
      if (toBlock && fromBlock) {
        const { fromStateTransitions } = getConnectionsFromBlocks(state.graph.blocks);
        state.graph.fromStateTransitions = fromStateTransitions;
      }
    },
    restoreConnection: (
      state,
      action: PayloadAction<{
        connector: TConnector;
        prevFromNodeScreen: JStateWithId;
        prevToNodeScreen: JStateWithId;
      }>
    ) => {
      const { connector, prevToNodeScreen, prevFromNodeScreen } = action.payload;
      const fromParams = getConnectorPropsFromName(connector.from);
      let foundBlockFrom = findScreenByPathId(fromParams.blockPathId, state.graph.blocks);
      let toBlockPath = findScreenByPathId(connector.to || '', state.graph.blocks)?.path;
      if (!foundBlockFrom || !toBlockPath) return;
      const toScreen = findScreenByPath(toBlockPath, state.graph.blocks);

      foundBlockFrom.blocks = prevFromNodeScreen.blocks;
      if (fromParams.isFromState && toScreen) {
        toScreen.blocks = prevToNodeScreen.blocks;
      }
      makeFromStateConnections(foundBlockFrom, state.graph);
    },
    deleteBlockInScreen: (
      state,
      action: PayloadAction<{
        screenId: string;
        blockIndex: number;
        path?: string;
      }>
    ) => {
      const { screenId, blockIndex, path } = action.payload;
      const newScreens = [...state.graph.blocks];
      let foundScreen = findScreenByPathId(screenId, newScreens);
      if (foundScreen) {
        const subStates = getAllInnerStates(foundScreen).map(state => state.pathId);
        cleanFromStateMapConnections(state.graph.fromStateTransitions, [foundScreen.pathId, ...subStates]);

        if (path && ScreenBlockPath.isPathRecursive(path)) {
          const possibleBlockEditPath = ScreenBlockPath.getBlockByPath(foundScreen.blocks, path);
          if (possibleBlockEditPath?.jblocks) {
            possibleBlockEditPath.jblocks.splice(
              blockIndex,
              ScreenBlockPath.getBlockWithNeighborsCount(possibleBlockEditPath.jblocks, blockIndex)
            );
          }
        } else {
          foundScreen.blocks.splice(blockIndex, 1);
          while (
            foundScreen.blocks[blockIndex] &&
            [TagNames.else, TagNames.elseif].includes(foundScreen.blocks[blockIndex].tagName)
          ) {
            foundScreen.blocks.splice(blockIndex, 1);
          }
        }

        /* const { fromStateTransitions } = getConnectionsFromBlocks(
            [foundScreen],
            new FromStateConnectionsStore({ ...current(state.graph.fromStateTransitions) })
          );
          state.graph.fromStateTransitions = fromStateTransitions;*/
        makeFromStateConnections(foundScreen, state.graph);

        const screenToSave = cloneDeep(foundScreen) as JStateWithId;

        if (!state.editMenuBlock) return;
        const editBlock = ScreenBlockPath.getBlockByPathAndIndex(
          screenToSave.blocks,
          state.editMenuBlock.path,
          state.editMenuBlock.jBlockIndex
        );
        state.editMenuBlock.screen = screenToSave;
        if (!editBlock) {
          state.editMenuBlock.jBlockIndex = undefined;
          state.editMenuBlock.path = undefined;
        }
        EditMenuBlock$.next({ ...state.editMenuBlock });
      }
    },
    resortBlocksInScreen: (state, action: PayloadAction<{ newBlocks: TJBlock[] }>) => {
      const { newBlocks } = action.payload;
      if (!state.editMenuBlock?.screen) return;
      const newScreen = findScreenByPathId(state.editMenuBlock?.screen.pathId, state.graph.blocks);
      if (!newScreen) return;
      newScreen.id = uuid();
      const subStates = getAllInnerStates(newScreen).map(state => state.pathId);
      cleanFromStateMapConnections(state.graph.fromStateTransitions, [newScreen.pathId, ...subStates]);
      newScreen.blocks = newBlocks;
      const { fromStateTransitions } = getConnectionsFromBlocks(
        [newScreen],
        new FromStateConnectionsStore({ ...current(state.graph.fromStateTransitions) })
      );
      state.graph.fromStateTransitions = fromStateTransitions;
      state.editMenuBlock.screen = newScreen;
      EditMenuBlock$.next(current(state.editMenuBlock));
    },
    resortBlocksInIf: (state, action: PayloadAction<{ newBlocks: TJBlock[]; contextPrefix: string }>) => {
      const { newBlocks, contextPrefix } = action.payload;
      if (!state.editMenuBlock?.screen) return;
      const newScreen = findScreenByPathId(state.editMenuBlock?.screen.pathId, state.graph.blocks);
      if (!newScreen) return;
      const blocksInScreen = ScreenBlockPath.getCurrentIfBlock(newScreen.blocks, contextPrefix);
      if (!blocksInScreen) return;

      const subStates = getAllInnerStates(newScreen).map(state => state.pathId);
      cleanFromStateMapConnections(state.graph.fromStateTransitions, [newScreen.pathId, ...subStates]);
      blocksInScreen.jblocks = newBlocks;
      const { fromStateTransitions } = getConnectionsFromBlocks(
        [newScreen],
        new FromStateConnectionsStore({ ...current(state.graph.fromStateTransitions) })
      );
      state.graph.fromStateTransitions = fromStateTransitions;

      state.editMenuBlock.screen = newScreen;
      EditMenuBlock$.next(current(state.editMenuBlock));
    },
    openScreenCreationMenu: (
      state,
      action: PayloadAction<{
        screenPosition: Vector2d;
        pointerPosition: Vector2d;
        from?: string;
        transitionTo?: string;
        transitionFrom?: string;
        parentStatePath?: string;
        parentThemeValue?: string;
        isOpenedByButton?: boolean;
        fixedParentPath?: boolean;
      }>
    ) => {
      if (!state.isEditModeEnable) return;
      const {
        screenPosition,
        pointerPosition,
        from,
        transitionTo,
        parentStatePath,
        isOpenedByButton,
        fixedParentPath,
        parentThemeValue,
      } = action.payload;
      let transitionFrom;
      if (from) {
        const connectorProps = getConnectorPropsFromName(from);
        const transitionFromBlock = findScreenByPathId(connectorProps.blockPathId, state.graph.blocks);
        if (transitionFromBlock) {
          transitionFrom = transitionFromBlock.path;
        }
      }

      state.screenCreationMenu.open = true;
      state.screenCreationMenu.screenPosition = screenPosition;
      state.screenCreationMenu.pointerPosition = pointerPosition;
      state.screenCreationMenu.from = from;
      state.screenCreationMenu.toPath = transitionTo;
      state.screenCreationMenu.fromPath = transitionFrom;
      state.screenCreationMenu.parentStatePath = parentStatePath;
      state.screenCreationMenu.isOpenedByButton = isOpenedByButton;
      state.screenCreationMenu.fixedParentPath = fixedParentPath;
      state.screenCreationMenu.parentThemeValue = parentThemeValue;
    },
    closeScreenCreationMenu: state => {
      if (state.screenCreationMenu.open) {
        state.screenCreationMenu.open = false;
        state.screenCreationMenu.from = undefined;
        state.screenCreationMenu.toPath = undefined;
        state.screenCreationMenu.fromPath = undefined;
        state.screenCreationMenu.screenPosition = { x: 0, y: 0 };
        state.screenCreationMenu.pointerPosition = { x: 0, y: 0 };
        state.screenCreationMenu.isOpenedByButton = undefined;
        state.screenCreationMenu.fixedParentPath = undefined;
        state.screenCreationMenu.parentThemeValue = undefined;
      }
    },
    openThemeCreationMenu: (
      state,
      action: PayloadAction<{ themePosition: Vector2d; pointerPosition: Vector2d; isOpenedByButton?: boolean }>
    ) => {
      if (!state.isEditModeEnable) return;
      state.themeCreationMenu.open = true;
      state.themeCreationMenu.themePosition = action.payload.themePosition;
      state.themeCreationMenu.pointerPosition = action.payload.pointerPosition;
      state.themeCreationMenu.isOpenedByButton = action.payload.isOpenedByButton;
    },
    closeThemeCreationMenu: state => {
      state.themeCreationMenu = {
        open: false,
        themePosition: { x: 0, y: 0 },
        pointerPosition: { x: 0, y: 0 },
      };
    },
    openContextMenu: (
      state,
      action: PayloadAction<{
        screenPosition: Vector2d;
        pointerPosition: Vector2d;
        statePosition?: Vector2d;
        statePath?: string;
        themePath?: string;
      }>
    ) => {
      if (!state.isEditModeEnable) return;
      const { screenPosition, pointerPosition, statePath, statePosition, themePath } = action.payload;

      state.contextMenu.open = true;
      state.contextMenu.screenPosition = screenPosition;
      state.contextMenu.pointerPosition = pointerPosition;
      state.contextMenu.statePath = statePath;
      state.contextMenu.statePosition = statePosition;
      state.contextMenu.themePath = themePath;
    },
    closeContextMenu: state => {
      if (!state.contextMenu.open) return;
      state.contextMenu.open = false;
      state.contextMenu.statePath = undefined;
      state.contextMenu.themePath = undefined;
      state.contextMenu.statePosition = undefined;
      state.contextMenu.screenPosition = { x: 0, y: 0 };
      state.contextMenu.pointerPosition = { x: 0, y: 0 };
    },
    openThemesPage: state => {
      state.isThemesOpen = true;
      state.selectedTheme = undefined;

      state.editMenuBlock = undefined;
      setTimeout(() => {
        EditMenuBlock$.next(undefined);
      }, 500);
    },
    closeThemesPage: state => {
      state.isThemesOpen = false;
      JGraphSlice.caseReducers.closeThemeCreationMenu(state);
    },
    setSelectedTheme: (state, action: PayloadAction<{ value?: string }>) => {
      state.selectedTheme = state.graph.themes.find(el => action.payload.value === el.value);
      JGraphSlice.caseReducers.closeThemesPage(state);
    },
    saveThemeMovement: (state, action: PayloadAction<{ newPositions: Vector2d; themeValue: string }>) => {
      const { newPositions, themeValue } = action.payload;
      const themes = [...state.graph.themes];
      const currentTheme = themes.find(theme => theme.value === themeValue);
      if (!currentTheme) return;
      currentTheme.x = newPositions.x;
      currentTheme.y = newPositions.y;
    },
    batchMoveThemes: (
      state,
      action: PayloadAction<{
        themes: Record<string, Vector2d>;
        type: AutoLayoutType;
      }>
    ) => {
      for (let [themeValue, newPositions] of Object.entries(action.payload.themes)) {
        const currentTheme = state.graph.themes.find(theme => theme.pathId === themeValue);
        if (!currentTheme) continue;
        currentTheme.x = newPositions.x;
        currentTheme.y = newPositions.y;
      }
      rafAppScheduler(() => {
        themePositionBatch$.next(Object.entries(action.payload.themes).map(([id, pos]) => ({ themeId: id, pos })));
      });
    },
    saveEvent: (
      state,
      action: PayloadAction<{
        value: string;
        isGlobal: boolean;
        tagParameters: TActivationParameters;
        targetState?: JStateWithId;
      }>
    ) => {
      const { value, isGlobal, tagParameters, targetState } = action.payload;
      const editMenuScreen = targetState || state.editMenuBlock?.screen;
      if (!editMenuScreen) return;
      const screenToSave = findScreenByPathId(editMenuScreen.pathId, state.graph.blocks);
      if (screenToSave) {
        screenToSave.id = uuid();
        const tagParamsObj = tagParametersToObj(tagParameters);
        if (tagParamsObj.toState?.value) {
          screenToSave.blocks.push({
            tagName: isGlobal ? TagNames.event_ : TagNames.event,
            tagValue: value,
            tagParameters: tagParameters,
            jblocks: [],
          });
        } else {
          screenToSave.blocks.splice(0, 0, {
            tagName: isGlobal ? TagNames.event_ : TagNames.event,
            tagValue: value,
            tagParameters: tagParameters,
            jblocks: [],
          });
        }

        const fromState = tagParameters.find(tagParam => tagParam.name === 'fromState');
        if (fromState && fromState.value !== '') {
          makeFromStateConnections(screenToSave, state.graph);
        }
        if (editMenuScreen && !targetState) {
          state.editMenuBlock = {
            screen: screenToSave,
          };
          EditMenuBlock$.next({
            screen: { ...current(screenToSave) },
          });
        } else {
          if (state.editMenuBlock) {
            state.editMenuBlock = {
              screen: state.editMenuBlock.screen,
            };
            EditMenuBlock$.next({
              screen: { ...current(state.editMenuBlock.screen) },
            });
          }
        }
      }
    },
    savePatterns: (
      state,
      action: PayloadAction<{
        value: string;
        isGlobal: boolean;
        tagParameters: TActivationParameters;
        targetState?: JStateWithId;
      }>
    ) => {
      const { value, isGlobal, tagParameters, targetState } = action.payload;
      const editMenuScreen = targetState || state.editMenuBlock?.screen;
      const screenToSave = findScreenByPathId(editMenuScreen!.pathId, state.graph.blocks);
      if (screenToSave) {
        screenToSave.id = uuid();
        const tagParamsObj = tagParametersToObj(tagParameters);
        if (tagParamsObj.toState?.value) {
          screenToSave.blocks.push({
            tagName: isGlobal ? TagNames.q_ : TagNames.q,
            tagValue: value,
            tagParameters: tagParameters,
            jblocks: [],
          });
        } else {
          screenToSave.blocks.splice(0, 0, {
            tagName: isGlobal ? TagNames.q_ : TagNames.q,
            tagValue: value,
            tagParameters: tagParameters,
            jblocks: [],
          });
        }

        const fromState = tagParameters.find(tagParam => tagParam.name === 'fromState');
        if (fromState && fromState.value !== '') {
          makeFromStateConnections(screenToSave, state.graph);
        }
        if (editMenuScreen && !targetState) {
          state.editMenuBlock = {
            screen: screenToSave,
          };
          EditMenuBlock$.next({
            screen: { ...current(screenToSave) },
          });
        } else {
          if (state.editMenuBlock) {
            state.editMenuBlock = {
              screen: state.editMenuBlock.screen,
            };
            EditMenuBlock$.next({
              screen: { ...current(state.editMenuBlock.screen) },
            });
          }
        }
      }
    },
    saveLabel: (
      state,
      action: PayloadAction<{
        text: string;
        color: string;
        targetState: JStateWithId;
      }>
    ) => {
      const { text, color, targetState } = action.payload;
      const screenToSave = findScreenByPathId(targetState.pathId, state.graph.blocks);
      if (!screenToSave) return;
      LabelingService.setLabelToScreenInPlace(screenToSave, text, color);
    },
    deleteLabel: (
      state,
      action: PayloadAction<{
        targetState: JStateWithId;
      }>
    ) => {
      const screenToSave = findScreenByPathId(action.payload.targetState.pathId, state.graph.blocks);
      if (!screenToSave) return;
      LabelingService.deleteLabelFromScreenInPlace(screenToSave);
    },
    saveIntent: (
      state,
      action: PayloadAction<{
        value: string;
        isGlobal: boolean;
        tagParameters: TActivationParameters;
        targetState?: JStateWithId;
        shouldGoDetail?: boolean;
      }>
    ) => {
      const { value, isGlobal, tagParameters, targetState, shouldGoDetail } = action.payload;
      const editMenuScreen = targetState || state.editMenuBlock?.screen;
      const screenToSave = findScreenByPathId(editMenuScreen!.pathId, state.graph.blocks);

      let intentIndexToOpenDetail = -1;

      if (screenToSave) {
        screenToSave.id = uuid();
        const tagParamsObj = tagParametersToObj(tagParameters);
        if (tagParamsObj.toState?.value) {
          //FYI FE-kostyl to add intents before local noMatch event
          const noMatchIndex = screenToSave.blocks.findIndex(
            block => block.tagName === TagNames.event && block.tagValue === 'noMatch'
          );
          if (noMatchIndex > -1) {
            intentIndexToOpenDetail = noMatchIndex;
            screenToSave.blocks.splice(noMatchIndex, 0, {
              tagName: isGlobal ? TagNames.intent_ : TagNames.intent,
              tagValue: value,
              tagParameters: tagParameters,
              jblocks: [],
            });
          } else {
            screenToSave.blocks.push({
              tagName: isGlobal ? TagNames.intent_ : TagNames.intent,
              tagValue: value,
              tagParameters: tagParameters,
              jblocks: [],
            });
            intentIndexToOpenDetail = current(screenToSave.blocks).length - 1;
          }
        } else {
          screenToSave.blocks.splice(0, 0, {
            tagName: isGlobal ? TagNames.intent_ : TagNames.intent,
            tagValue: value,
            tagParameters: tagParameters,
            jblocks: [],
          });
          intentIndexToOpenDetail = current(screenToSave.blocks).length - 1;
        }

        const fromState = tagParameters.find(tagParam => tagParam.name === 'fromState');
        if (fromState && fromState.value !== '') {
          makeFromStateConnections(screenToSave, state.graph);
        }
        if (editMenuScreen && !targetState) {
          state.editMenuBlock = {
            screen: screenToSave,
            jBlockIndex: shouldGoDetail ? intentIndexToOpenDetail : undefined,
          };
          EditMenuBlock$.next({
            screen: { ...current(screenToSave) },
            jBlockIndex: shouldGoDetail ? intentIndexToOpenDetail : undefined,
          });
        } else {
          if (state.editMenuBlock) {
            state.editMenuBlock = {
              screen: state.editMenuBlock.screen,
            };
            EditMenuBlock$.next({
              screen: { ...current(state.editMenuBlock.screen) },
            });
          }
        }
        guideTourEvent$.next(`AddedNewBlock::intent`);
      }
    },
    addOrRemoveElse: (
      state,
      action: PayloadAction<{
        path: string;
        blockIndex: number;
        tag: TagNames;
      }>
    ) => {
      const { path, blockIndex, tag } = action.payload;
      const screen = state.editMenuBlock?.screen;

      if (screen) {
        let foundEditBlock = findScreenByPathId(screen.pathId, state.graph.blocks);
        if (foundEditBlock) {
          const currentIfPath = ScreenBlockPath.getCurrentIfBlockNumberPath(path);
          const parentPath = [...currentIfPath];
          parentPath.splice(-1, 1);
          if (parentPath.length > 0) {
            let parent = ScreenBlockPath.getBlockByNumberPath(screen.blocks, parentPath);
            if (parent) {
              let currentBlock = parent.jblocks[blockIndex];
              currentBlock.tagName = tag;
              if (tag === TagNames.else) {
                currentBlock.tagValue = '';
                parent.jblocks = moveBlocksFromDescendingTags(currentBlock, blockIndex, parent.jblocks);
              }
              if (tag === TagNames.elseif) {
                parent.jblocks = [...parent.jblocks];
              }
            }
          } else {
            let parent = screen;
            //TODO remove dirty copypaste
            let currentBlock = parent.blocks[blockIndex];
            currentBlock.tagName = tag;
            if (tag === TagNames.else) {
              currentBlock.tagValue = '';
              parent.blocks = moveBlocksFromDescendingTags(currentBlock, blockIndex, parent.blocks);
            }
            if (tag === TagNames.elseif) {
              parent.blocks = [...parent.blocks];
            }
          }
          screen.isDirty = true;
          foundEditBlock.blocks = screen.blocks;
        }
      }
    },
    cleanUp: () => {
      return initialState;
    },
    stopDebug: state => {
      debugDataState$.next('clear');
      state.previousHighLightStates.forEach(statePath => {
        const screen = findScreenByPath(statePath, state.graph.blocks);
        if (screen && screen.debugActive) {
          screen.debugActive = false;
          recursiveCleanUpDebugData(screen.blocks);
        }
        if (screen && screen.debugLastActive) {
          screen.debugLastActive = false;
        }
      });
      state.previousHighLightStates = [];
      state.tagLocators = [];
      state.tagLocatorsLoaded = false;
      state.locatorsMap = {};
    },
    onDebugMessageReceived: (
      state,
      action: PayloadAction<{
        whatToHighlight: JWidgetMessageLocator[];
      }>
    ) => {
      //clean previous highlight
      state.previousHighLightStates.forEach(statePath => {
        const screen = findScreenByPath(statePath, state.graph.blocks);
        if (screen && screen.debugActive) {
          screen.debugActive = false;
          recursiveCleanUpDebugData(screen.blocks);
        }
        if (screen && screen.debugLastActive) {
          screen.debugLastActive = false;
        }
      });

      let screenCenterTo: JStateWithId | undefined = undefined;
      //highlight new
      let newPreviousHighlightStates = new Set<string>();

      action.payload.whatToHighlight.forEach(locator => {
        let Locator = locator.locator;
        //@ts-ignore BE-error locator can be string
        if (typeof Locator === 'string') {
          Locator = JSON.parse(Locator);
        }
        if (!Locator) return;

        const fileName = Locator.filename.startsWith('/') ? Locator.filename : `/${Locator.filename}`;
        if (
          state.locatorsMap[fileName] &&
          state.locatorsMap[fileName][Locator.line] !== undefined &&
          locator.tagStatePath
        ) {
          const activeScreen = findScreenByPath(locator.tagStatePath, state.graph.blocks);
          if (activeScreen) {
            const blockPath = state.locatorsMap[fileName][Locator.line];
            const block = ScreenBlockPath.getBlockByNumberPath(activeScreen.blocks, blockPath);

            if (block) {
              switch (block?.tagName) {
                case TagNames.buttons: {
                  if (locator.toStatePath) {
                    block?.tagParameters.forEach(param => {
                      if (param.transition === locator.toStatePath) param.debugActive = true;
                    });
                  }
                  break;
                }
                default:
                  if (startsWithCapital(block.tagName) && locator.toState) {
                    //SEEM's CustomTag TODO use function from edit custom tag
                    block?.tagParameters.forEach(param => {
                      if (param.value === locator.toState) param.debugActive = true;
                    });
                  }
                  block.debugActive = true;

                  if (locator.tagStatePath !== locator.toStatePath) {
                    block.debugTransition = true;
                  }
                  break;
              }
            }
          }
        }

        if (locator.tagStatePath) {
          const activeScreen = findScreenByPath(locator.tagStatePath, state.graph.blocks);
          if (activeScreen) {
            activeScreen.debugActive = true;
            newPreviousHighlightStates.add(locator.tagStatePath);
            screenCenterTo = activeScreen;
          }
        }
        if (locator.toStatePath) {
          const activeScreen = findScreenByPath(locator.toStatePath, state.graph.blocks);
          if (activeScreen) {
            activeScreen.debugActive = true;
            newPreviousHighlightStates.add(locator.toStatePath);
            screenCenterTo = activeScreen;
          }
        }
        if (locator.toState) {
          const activeScreen = findScreenByPath(locator.toState, state.graph.blocks);
          if (activeScreen) {
            activeScreen.debugActive = true;
            newPreviousHighlightStates.add(locator.toState);
            screenCenterTo = activeScreen;
          }
        }
      });
      if (screenCenterTo) {
        (screenCenterTo as JStateWithId).debugLastActive = true;
        screenCenterTo = current(screenCenterTo);
      }

      setTimeout(() => {
        window.requestAnimationFrame(() => {
          if (screenCenterTo) {
            screenCenterTo = screenCenterTo as JStateWithId;
            scrollToTargetGlobal$.next({
              targetPathId: screenCenterTo.pathId,
            });
          }
        });
      }, 0);

      state.previousHighLightStates = Array.from(newPreviousHighlightStates);
    },
    updateLastModification: (state, action: PayloadAction<{ lastModification: number; filename: string }>) => {
      state.graph.files[action.payload.filename] = action.payload.lastModification;
    },
    reverting: (state, action: PayloadAction<boolean>) => {
      state.graph.reverting = action.payload;
    },
    loadStickers: (state, action: PayloadAction<JGraphStickersInfo>) => {
      state.stickers = action.payload.stickers.map(normalizeSticker);
      state.stickersVersion.lastModified = action.payload.lastModified;
      state.stickersVersion.version = action.payload.version;
    },
    loadStickersVersions: (state, action: PayloadAction<Omit<JGraphStickersInfo, 'stickers'>>) => {
      state.stickersVersion.lastModified = action.payload.lastModified;
      state.stickersVersion.version = action.payload.version;
    },
    restoreStickers: (state, action: PayloadAction<StickerInfo[]>) => {
      state.stickers = action.payload;
    },
    addSticker: (state, action: PayloadAction<Omit<StickerInfo, 'history'>>) => {
      state.stickers.push(action.payload);
    },
    deleteSticker: (state, action: PayloadAction<StickerInfo>) => {
      const sticker = action.payload;
      const index = state.stickers.findIndex(el => el.id === sticker.id);
      state.stickers.splice(index, 1);
    },
    updateSticker: (state, action: PayloadAction<StickerInfo>) => {
      const sticker = action.payload;
      const index = state.stickers.findIndex(el => el.id === sticker.id);
      state.stickers.splice(index, 1, sticker);
    },
    updateConnectedStickersAfterStateUpdate: (
      state,
      action: PayloadAction<{
        stateBeforeUpdate: { x: number; y: number; path: string };
        stateAfterUpdate: { x: number; y: number; path: string };
      }>
    ) => {
      const payload = action.payload;
      const stickers = state.stickers;

      const stickersConnectedToMovedState = stickers.filter(
        sticker => sticker.connection?.statePath === payload.stateBeforeUpdate.path
      );

      if (stickersConnectedToMovedState.length === 0) return;

      stickersConnectedToMovedState.forEach(sticker => {
        const relativePositionByState = Vector2D.fromObj(sticker.position).subtract(payload.stateBeforeUpdate);
        sticker.position = Vector2D.fromObj(payload.stateAfterUpdate).add(relativePositionByState);
      });
    },
    deleteThemeInState: (state, action: PayloadAction<{ themeValue: string }>) => {
      const payload = action.payload;
      const newThemes = state.graph.themes.filter(theme => theme.value !== payload.themeValue);
      state.graph = {
        ...state.graph,
        ...rebuildGraph({
          themes: newThemes,
          states: state.graph.blocks,
          entryPointFileName: state.entrypoint.filename,
        }),
      };
    },
    createThemeInState: (state, action: PayloadAction<{ theme: JGraphTheme }>) => {
      const payload = action.payload;
      const newThemes = [...state.graph.themes.filter(theme => theme.value !== payload.theme.value), payload.theme];
      state.graph = {
        ...state.graph,
        ...rebuildGraph({
          themes: newThemes,
          states: state.graph.blocks,
          entryPointFileName: state.entrypoint.filename,
        }),
      };
    },
    renameThemeInState: (state, action: PayloadAction<{ oldValue: string; newValue: string }>) => {
      const payload = action.payload;
      const isNeedToResetEditedScreen = state.editMenuBlock?.screen.theme === payload.oldValue;
      const newThemes: JGraphTheme[] = [...(state.graph.themes as JGraphTheme[])];
      const themeIndex = newThemes.findIndex(theme => theme.value === payload.oldValue);
      if (themeIndex === -1) return;
      newThemes[themeIndex] = {
        ...newThemes[themeIndex],
        value: payload.newValue,
      };
      state.graph = {
        ...state.graph,
        ...rebuildGraph({
          themes: newThemes,
          states: state.graph.blocks,
          entryPointFileName: state.entrypoint.filename,
        }),
      };
      state.selectedTheme = state.graph.themes.find(theme => theme.value === payload.newValue);
      if (isNeedToResetEditedScreen) {
        state.editMenuBlock = undefined;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getJGraphVisuals.fulfilled, (state, action) => {
        state.visuals.loading = false;
        state.visuals.visualsData = action.payload;
        hiddenConnections$.next({
          from: action.payload.data.connectors.from,
          to: action.payload.data.connectors.to,
          action: 'load',
        });
        StateCollapseSubject$.next({
          statePaths: action.payload.data.collapsedStates,
        });
      })
      .addCase(updateJGraphVisuals.fulfilled, (state, action) => {
        state.visuals.visualsData = {
          ...state.visuals.visualsData,
          lastModified: action.payload.lastModified,
        };
      })
      .addCase(getGraph.fulfilled, (state, action) => {
        state.error = undefined;
        if (action.payload) {
          RerenderScreensEvent$.next(null);
          const isFirstLoad = state.graph.blocks.length === 0;
          state.graph = dtoToGraph(action.payload);

          if (isFirstLoad && state.graph.themes.length === 1) {
            JGraphSlice.caseReducers.closeThemesPage(state);
            JGraphSlice.caseReducers.setSelectedTheme(state, {
              type: 'JGraphSlice/setSelectedTheme',
              payload: { value: state.graph.themes[0].value },
            });
          }

          state.entrypoint = action.payload.entrypoint;
          state.stickers = action.payload.stickers.stickers.map(normalizeSticker);
          state.stickersVersion = action.payload.stickers;
        }
        state.loadingGraph = false;
      })
      .addCase(getGraph.rejected, (state, action) => {
        if (action.payload) {
          state.error = {
            code: action.payload.error || '',
            message: action.payload.message || '',
            args: action.payload?.args,
          };
        } else {
          state.error = {
            code: action.error.message || '',
            message: action.error.message || '',
          };
        }
        state.loadingGraph = false;
      })
      .addCase(getGraphLocators.fulfilled, (state, action) => {
        // tagLocatorsState.next(action.payload.jstates);
        state.tagLocators = action.payload.jstates;
        const LocatorsMap: Record<string, Record<number, number[]>> = {};
        action.payload.jstates.forEach(jstateLocator => {
          jstateLocator.blocks.forEach((block, index) => {
            LocatorsMap[block.locator.filename] = LocatorsMap[block.locator.filename] || {};
            LocatorsMap[block.locator.filename][block.locator.line] = [index];
            fillJBlocksPathByLine(LocatorsMap[block.locator.filename], [index], block.jblocks);
          });
        });
        state.locatorsMap = LocatorsMap;
        state.tagLocatorsLoaded = true;
      })
      .addCase(getCustomTags.fulfilled, (state, action) => {
        let customTags = action.payload.filter(customTagData => !customTagData.jgraphHidden);
        state.customTags = customTags;

        const topLevelLocalizationFields = ['caption', 'hint', 'description'] as const;
        const localizations = action.payload.reduce(
          (loca, currentValue) => {
            topLevelLocalizationFields.forEach(param => {
              if (currentValue[param] && currentValue.tagName) {
                if (!loca[`CustomTag:${currentValue.tagName}:${param}`])
                  loca[`CustomTag:${currentValue.tagName}:${param}`] = {};
                //@ts-ignore
                loca[`CustomTag:${currentValue.tagName}:${param}`] = currentValue[param];
              }
            });

            if (currentValue.parameters && currentValue.tagName) {
              currentValue.parameters.forEach(param => {
                if (param.localization) {
                  if (!loca[`CustomTag:${currentValue.tagName}:param:${param.name}`])
                    loca[`CustomTag:${currentValue.tagName}:param:${param.name}`] = {};

                  loca[`CustomTag:${currentValue.tagName}:param:${param.name}`] = param.localization;
                }
                if (param.description) {
                  if (!loca[`CustomTag:${currentValue.tagName}:param:${param.name}`])
                    loca[`CustomTag:${currentValue.tagName}:param:${param.name}`] = {};

                  loca[`CustomTag:${currentValue.tagName}:param:${param.name}:description`] = param.description;
                }
                if (param.description) {
                  if (!loca[`CustomTag:${currentValue.tagName}:${param.name}`])
                    loca[`CustomTag:${currentValue.tagName}:${param.name}`] = {};

                  loca[`CustomTag:${currentValue.tagName}:${param.name}:description`] = param.description;
                }
              });
            }
            return loca;
          },
          {} as Record<string, Record<string, string>>
        );
        localize.addTranslations(localizations);
        CustomTagsStore$.next(customTags);
        state.loadingCustomTags = false;
      })
      .addCase(updateState.fulfilled, (state, action) => {
        const foundScreen = findScreenByPath(action.meta.arg.path, state.graph.blocks);
        if (state.editMenuBlock && state.editMenuBlock.screen) {
          if (state.editMenuBlock.screen.path === action.meta.arg.path && state.editMenuBlock.screen.isDirty) {
            state.editMenuBlock.screen.isDirty = false;
          }
        }
        if (foundScreen) {
          if (foundScreen.hasOwnProperty('isDirty')) {
            foundScreen.isDirty = false;
          }
          makeFromStateConnections(foundScreen, state.graph);
        }
        state.graph.files[action.meta.arg.filename] = action.payload.lastModification;
      })
      .addCase(createState.fulfilled, (state, action) => {
        const newScreens = [...state.graph.blocks];
        const foundScreen = findScreenByPathId(action.meta.arg.pathId, newScreens);
        state.removedConnectorOnNewBlock = null;
        if (foundScreen && foundScreen.isUnsaved) {
          foundScreen.isUnsaved = false;
        }
        if (state.editMenuBlock && state.editMenuBlock.screen.isUnsaved) {
          state.editMenuBlock.screen.isUnsaved = false;
          const currentEditMenuBlock = current(state.editMenuBlock);
          EditMenuBlock$.next({
            ...currentEditMenuBlock,
          });
        }
        state.graph.files[action.meta.arg.filename] = action.payload.lastModification;
        // console.log(action, action.payload);
      })
      .addCase(revertCreateState.fulfilled, (state, action) => {
        state.graph.files[action.meta.arg.payload.filename] = action.payload.lastModification;
      })
      .addCase(revertDeleteState.fulfilled, (state, action) => {
        if (!action.payload?.lastModification) return;
        state.graph.files[action.meta.arg.payload.filename] = action.payload.lastModification;
      })
      .addCase(deleteTheme.fulfilled, (state, action) => {
        const response = action.payload?.data;
        if (!response) return;
        response.forEach(file => {
          state.graph.files[file.filename] = file.lastModification;
        });
      })
      .addMatcher(
        () => true,
        (state, action) => {
          setTimeout(() => {
            if (isPending(action)) JGraphReduxActions$.next({ type: JGraphReduxActionEventStatus.PENDING, action });
            if (isFulfilled(action)) JGraphReduxActions$.next({ type: JGraphReduxActionEventStatus.SUCCESS, action });
            if (isRejected(action)) JGraphReduxActions$.next({ type: JGraphReduxActionEventStatus.ERROR, action });
          }, 500);
        }
      );
  },
});

export const {
  toggleAddingActionsMenu,
  closeStateNameEditField,
  showStateNameEditField,
  changeStateName,
  saveBlockMovement,
  setEditMenuBlock,
  saveScreen,
  addNewBlockInState,
  saveLabel,
  setJGraphInEditMode,
  deleteLabel,
  addNewState,
  restoreState,
  makeNewConnector,
  deleteBlockInScreen,
  resortBlocksInScreen,
  openScreenCreationMenu,
  closeScreenCreationMenu,
  openContextMenu,
  closeContextMenu,
  saveEvent,
  savePatterns,
  saveIntent,
  onAddingMenuElementClick,
  cleanUpStateOnAddingMenu,
  deleteConnection,
  restoreConnection,
  setBlockPositions,
  cleanUnsaved,
  addOrRemoveElse,
  resortBlocksInIf,
  cleanUp,
  onDebugMessageReceived,
  stopDebug,
  migrateTextToRandom,
  updateLastModification,
  reverting,
  deleteSticker,
  updateSticker,
  addSticker,
  loadStickers,
  loadStickersVersions,
  restoreStickers,
  updateConnectedStickersAfterStateUpdate,
  openAddingMenu,
  openThemesPage,
  closeThemesPage,
  setSelectedTheme,
  saveThemeMovement,
  batchMoveThemes,
  openThemeCreationMenu,
  closeThemeCreationMenu,
  deleteThemeInState,
  createThemeInState,
  renameThemeInState,
} = JGraphSlice.actions;

export default JGraphSlice.reducer;
