import { init } from '@sentry/react';
import { BrowserTracing, Replay } from '@sentry/browser';
import { AppLogger, LogLevel } from '@just-ai/logger';
import { SentryLogTransport } from '@just-ai/logger/build/Transports/SentryLogTransport';

import { isDev } from 'pipes/pureFunctions';

if (!isDev() && window.env?.SENTRY_DSN && window.env?.SENTRY_RELEASE) {
  init({
    dsn: window.env.SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing(),
      new Replay({
        maskAllText: true,
        blockAllMedia: true,
        networkDetailAllowUrls: ['/restapi', '/api', '/cailapub'],
      }),
    ],
    tracesSampleRate: 1,
    release: window.env.SENTRY_RELEASE,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      'VK is not defined',
      "Can't find variable: VK",
      "Unexpected token '<'",
      // Aborts
      'Request aborted',
      'AbortError: Fetch is aborted',
      // Error is not a js type Error, maybe some object
      'Non-Error exception captured',
      // Error with empty message
      'Non-Error promise rejection',
    ],
    denyUrls: [/https:\/\/mc.yandex.ru\/+/, /https:\/\/www\.gstatic.com\/+/, /.*?experrto.io.*/],
  });
  AppLogger.addTransport('sentry', new SentryLogTransport(LogLevel.ERROR));
}
