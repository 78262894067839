import { useState, useEffect } from 'react';
import { ClipboardService } from 'modules/JGraph/services/ClipboardService';

export function clipboardListener(cb: (text: string) => void) {
  ClipboardService.readFromClipboard().then(cb);
  const documentFocusListener = () => ClipboardService.readFromClipboard().then(cb);
  window.addEventListener('focus', documentFocusListener);
  const unsub = ClipboardService.onClipboardCopy(cb);
  return () => {
    window.removeEventListener('focus', documentFocusListener);
    unsub();
  };
}

export function useClipboardListener() {
  const [clipboardText, setClipboardText] = useState('');
  useEffect(() => clipboardListener(setClipboardText), []);
  return clipboardText;
}
