import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, Fragment } from 'react';
import { getUserValidName } from '../../../../utils/pipes/pureFunctions';
import classNames from 'classnames';
import { Icon, Spoiler } from '@just-ai/just-ui';
import AudioBlock from './AudioBlock';
import { AnalyticsContext } from '../../../../context/AnalyticsContext';
export default function Question({ question, questionId, event, data, cleaned, utterancesJson, responseData, textAnswer, operatorInfo, requestData, t, showAnswers = true, startTimeDialog, }) {
    var _a, _b;
    const { accountId } = useContext(AnalyticsContext);
    let imageEvent = null;
    let fileEvent;
    let questionBargin;
    const answer = textAnswer || (responseData === null || responseData === void 0 ? void 0 : responseData.answer);
    if (!!event) {
        if (event === 'imageEvent') {
            if (!!(data === null || data === void 0 ? void 0 : data.eventData)) {
                imageEvent = data.eventData.url;
            }
        }
        if (event === 'fileEvent') {
            if (data && (data === null || data === void 0 ? void 0 : data.eventData)) {
                fileEvent = data.eventData;
            }
        }
        if (event === 'bargeInIntent') {
            questionBargin = (_a = requestData === null || requestData === void 0 ? void 0 : requestData.bargeInIntentStatus) === null || _a === void 0 ? void 0 : _a.recognitionResult.text;
        }
    }
    //@ts-ignore
    const rawReplies = ((responseData === null || responseData === void 0 ? void 0 : responseData.replies) && ((_b = responseData === null || responseData === void 0 ? void 0 : responseData.replies) === null || _b === void 0 ? void 0 : _b.length) > 0 && (responseData === null || responseData === void 0 ? void 0 : responseData.replies)) || answer || textAnswer;
    let replies = rawReplies;
    const repliesToRender = [];
    let repliesInlineButtons = [];
    let repliesButtons = [];
    let imageUrl = [];
    const googleSheets = responseData === null || responseData === void 0 ? void 0 : responseData.googleSheets;
    if (Boolean(rawReplies)) {
        try {
            if (typeof rawReplies !== 'object') {
                replies = [
                    {
                        text: rawReplies,
                        type: 'text',
                    },
                ];
            }
            if (Array.isArray(replies)) {
                replies.map((reply, key) => {
                    switch (reply.type) {
                        case 'raw':
                            repliesToRender.push(_jsx("div", { children: JSON.stringify(reply) || t('Message has been deleted') }, questionId + '_raw_' + key));
                            break;
                        case 'text': {
                            if (reply.hasOwnProperty('errorStacktrace')) {
                                repliesToRender.push(_jsxs(_Fragment, { children: [reply.text && _jsx("div", { children: reply.text }, questionId + '_error_textAnswer_' + key), _jsx("div", { className: 'text-danger', children: reply.errorStacktrace }, questionId + '_error_' + key)] }));
                                break;
                            }
                            repliesToRender.push(_jsx("div", { children: reply.text === 'null' ? t('Message has been deleted') : reply.text }, questionId + '_text_' + key));
                            break;
                        }
                        case 'inlineButtons': {
                            reply.buttons.forEach((button, index) => {
                                repliesInlineButtons.push(_jsx("button", { title: button.callback_data, className: 'btn btn-outline-info btn-sm mr-1 mb-1', children: button.text }, questionId + `_inlineButtons_${index}_` + key));
                            });
                            break;
                        }
                        case 'buttons': {
                            reply.buttons.forEach((button, index) => {
                                repliesButtons.push(_jsx("button", { className: 'btn btn-outline-info btn-sm mr-1 mb-1', children: button.text }, questionId + `_buttons_${index}_` + key));
                            });
                            break;
                        }
                        case 'image': {
                            imageUrl.push({ url: reply.imageUrl, text: reply.text });
                            break;
                        }
                        case 'file': {
                            repliesToRender.push(_jsx(File, { fromBot: true, file: { url: reply.fileUrl, name: reply.fileName, type: reply.mimeType }, index: questionId }));
                            break;
                        }
                        case 'audio': {
                            repliesToRender.push(_jsx("div", { children: _jsx("a", { rel: 'noopener noreferrer', href: reply.audioUrl, target: '_blank', children: reply.audioName ? reply.audioName : decodeURIComponent(reply.audioUrl) }) }, questionId + '_audio_' + key));
                            break;
                        }
                        case 'ttsTemplate': {
                            repliesToRender.push(_jsx("div", { children: reply.ttsTemplate.text === 'null' ? t('Message has been deleted') : reply.ttsTemplate.text }, questionId + '_text_' + key));
                            break;
                        }
                        case 'telegramPayment': {
                            repliesToRender.push(_jsxs("div", { className: 'ClientDialogMessage_payment', children: [t('Analytics: messages event'), ": ", reply.type, " ", reply.amount, " ", reply.currency] }, questionId + '_payment' + key));
                            break;
                        }
                        default:
                            break;
                    }
                });
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    return (_jsxs("div", { className: 'phrases-messages', children: [_jsx("div", { "data-test-id": 'messageCard_answer', className: 'phrases-messages--qa-item', children: _jsx(ClientReply, { imageEvent: imageEvent, fileEvent: fileEvent, cleaned: cleaned, question: questionBargin || question, utterancesJson: utterancesJson, accountId: accountId, timestamp: startTimeDialog, t: t }) }), showAnswers && (_jsxs(_Fragment, { children: [_jsxs("div", { className: classNames('qa-field qa-answer qa-block Message_answer_question', {
                            'qa-answer-operatorInfo': !!operatorInfo,
                        }), children: [_jsx("div", { className: 'text-muted phrases-messages--qa-bottom-text', children: t('Analytics: messages bot label') }), operatorInfo && (_jsxs("div", { className: 'text-muted small', children: [_jsx("strong", { children: `#${operatorInfo.id}` }), ' ', `${getUserValidName(operatorInfo.firstName, operatorInfo.lastName, t)}`] })), _jsxs("div", { children: [_jsxs("div", { children: [cleaned ? (_jsx("div", { children: _jsx("i", { children: t('Message has been deleted') }) })) : (repliesToRender), imageUrl[0] &&
                                                imageUrl.map(({ url, text }, index) => {
                                                    return (_jsxs(Fragment, { children: [url && (_jsx("div", { style: { marginTop: 8, marginBottom: 8 }, children: _jsx("img", { alt: 'message image', style: { maxHeight: 300 }, src: url }) })), text && _jsx("div", { children: text })] }, index));
                                                })] }), repliesInlineButtons.length > 0 && _jsx("div", { children: repliesInlineButtons }), repliesButtons.length > 0 && _jsx("div", { children: repliesButtons })] })] }), googleSheets && (_jsxs(_Fragment, { children: [_jsxs("div", { "data-test-id": 'messageCard_answer', className: 'phrases-messages--qa-item', children: [_jsx("div", { className: 'phrases-messages--qa-head phrases-messages--qa-head-without-icon', children: _jsxs("div", { className: 'text-muted phrases-messages--qa-bottom-text', children: [t('GoogleSheets'), ":"] }) }), _jsx("div", { className: 'qa-field qa-answer qa-block', children: _jsx("div", { children: googleSheets.integrationId }) })] }), _jsxs("div", { "data-test-id": 'messageCard_answer', className: 'phrases-messages--qa-item', children: [_jsx("div", { className: 'phrases-messages--qa-head phrases-messages--qa-head-without-icon', children: _jsxs("div", { className: 'text-muted phrases-messages--qa-bottom-text', children: [t('ProjectReview:volumeTable header result'), ":"] }) }), _jsx("div", { className: 'qa-field qa-answer qa-block', children: _jsx("div", { children: googleSheets.result }) })] })] }))] }))] }));
}
//@ts-ignore
const ClientReply = ({ imageEvent, fileEvent, cleaned, question, utterancesJson, t, accountId, timestamp }) => {
    let text = utterancesJson && utterancesJson.length ? getUtteranceQuestion(utterancesJson, t) : question;
    if (text === 'null')
        text = t('Message has been deleted');
    if (!text && !fileEvent && !imageEvent) {
        return null;
    }
    if (Array.isArray(utterancesJson) && utterancesJson.length > 0) {
        return (_jsx(Spoiler, { Header: ({ toggleOpen, isOpen }) => {
                return (_jsxs("div", { "data-test-id": 'Question.ClientReply.messageAudioSpoiler', className: 'text-muted Message_audio_head', onClick: toggleOpen, children: [_jsx("span", { children: t('Analytics: messages client label') }), _jsx(Icon, { name: isOpen ? 'farMinusSquare' : 'farPlusSquare', color: 'info', className: 'phrases-messages--qa-icon', onClick: toggleOpen }), ' ', _jsx("span", { children: utterancesJson.map(x => x.text).join(', ') || text })] }));
            }, children: _jsx("div", { className: 'qa-block qa-block--opened-bottom Message_audios_answers', children: utterancesJson.map((utterance, i) => (_jsx("div", { children: _jsx(AudioBlock, { url: `/restapi/download/${accountId}/recordings/asr/${utterance.resourcePath}`, title: utterance.text, meta: utterance, t: t, dataTestId: `Question.ClientReply-${i}`, startTime: timestamp }) }, `audio-utterance-${i}`))) }) }));
    }
    return (_jsxs("div", { className: 'phrases-messages--qa-container Message_answer_question', children: [_jsx("div", { className: 'phrases-messages--qa-head phrases-messages--qa-head-question', children: _jsx("div", { className: 'text-muted', children: t('Analytics: messages client label') }) }), _jsx("div", { className: 'qa-block', children: _jsxs("div", { className: 'qa-field qa-question', children: [imageEvent && _jsx("img", { src: imageEvent, alt: 'event' }), Array.isArray(fileEvent) &&
                            fileEvent.map((file, index) => Array.isArray(file) ? (file.map((file, k) => _jsx(File, { file: file, index: `${index}_${k}` }, `${index}_${k}`))) : (_jsx(File, { file: file, index: index }))), imageEvent || fileEvent ? ' ' : null, cleaned && (_jsx("div", { children: _jsx("i", { children: t('Message has been deleted') }) })), text] }) })] }));
};
function File({ file, index, fromBot, }) {
    return (_jsx("a", { className: 'text-decoration-none text-body', href: file.url, target: '_blank', rel: 'noreferrer', children: _jsxs("span", { className: 'py-2 px-3 bg-light rounded', children: [!fromBot && _jsxs(_Fragment, { children: [file.url.split('/').slice(-1)[0], " \u00A0"] }), _jsx(Icon, { name: 'farDownload' }), file.name && _jsx("span", { style: { marginLeft: '0.25rem' }, children: file.name })] }, `file_received_${file.url}_${index}`) }));
}
const getUtteranceQuestion = (utterancesJson, t) => utterancesJson.map((utterance, i) => {
    return !utterance.text || utterance.status === 'NoMatch' ? (_jsx("span", { className: 'qa-question--not-recognized', children: t('details phrase not recognized') })) : (_jsxs("span", { children: [utterance.text, " "] }, `utterance-${i}`));
});
