import axios from 'axios';

const TIMEOUT_VALUE = 30_000;

export class SyncFrontendVersionService {
  private timeoutId!: NodeJS.Timeout;

  public startWatch = (currentValue: string) => {
    axios
      .get(`/version`, {
        headers: {
          'Cache-Control': 'no-store',
          Pragma: 'no-cache',
          Expires: '0',
        },
      })
      .then(response => {
        if (response.data.buildNumber !== currentValue) {
          window.location.reload();
          return;
        }
        this.timeoutId = setTimeout(() => {
          this.startWatch(currentValue);
        }, TIMEOUT_VALUE);
      })
      .catch(error => console.error(error));
  };

  public stopWatch = () => {
    if (!this.timeoutId) return;
    clearTimeout(this.timeoutId);
  };
}
