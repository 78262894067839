import { useEffect, useCallback, useMemo, useRef } from 'react';
import { Vector2d } from 'konva/lib/types';
import keyboardJS from 'keyboardjs';
import { AppLogger } from '@just-ai/logger';

import { useAppDispatch, useAppSelector } from 'storeHooks';
import { useClipboardListener, clipboardListener } from 'utils/hooks/CopyToClipboardFeature';
import safeJsonParse from 'utils/safeJsonParse';

import { EditMenuBlock, JStateWithId } from 'reducers/JGraph.reducer/types';
import { addNewStateWithSave } from 'reducers/JGraph.reducer/JGraphAsyncActions';
import { getAllStates, resetConnections } from 'reducers/JGraph.reducer/Graph';
import { ClipboardService } from 'modules/JGraph/services/ClipboardService';
import { ScreenBlockPath } from 'reducers/JGraph.reducer/ScreenBlockPath';
import { TJBlock, TagNames, checkObjectIsTJBlock, checkObjectIsJGState } from 'modules/JGraph/utils/types';
import { EditMenuBlock$ } from 'modules/JGraph/view/StateScreen.hooks';
import { useStageObservableContext } from 'modules/JGraph/contexts/StageObservablesProvider';
import { renameInlineStateIfNameIsBusy, stateChangePath } from 'modules/JGraph/utils/themesUtils';
import { joinPaths } from './stageUtils';

export function useCopyToClipboardBlock(stateBlocks: TJBlock[] = [], blockPath = '', blockIndex?: number) {
  return useCallback(() => {
    const block = ScreenBlockPath.getBlockByPathAndIndex(stateBlocks, blockPath, blockIndex);
    if (!block) {
      AppLogger.error(
        { message: 'useCopyToClipboardBlock: Unable to find block to copy' },
        { stateBlocks, blockPath, blockIndex }
      );
      return;
    }
    let blocks: TJBlock[] = [block];
    if (block.tagName === TagNames.if && blockIndex) {
      blocks = ScreenBlockPath.getIfChainBlocks(blockIndex, stateBlocks);
    }
    ClipboardService.copyToClipboard(JSON.stringify(blocks));
  }, [stateBlocks, blockPath, blockIndex]);
}

export function useStateBlocksInClipboard() {
  const clipboardText = useClipboardListener();
  return useMemo(() => {
    if (!clipboardText) return null;
    const parsedJson = safeJsonParse(clipboardText);
    if (!parsedJson) return null;
    if (Array.isArray(parsedJson)) {
      if (!parsedJson.every(checkObjectIsTJBlock)) return null;
      return parsedJson;
    }
    if (!checkObjectIsTJBlock(parsedJson)) return null;
    return [parsedJson];
  }, [clipboardText]);
}

export function useStateInClipboard() {
  const clipboardText = useClipboardListener();
  return useMemo(() => {
    if (!clipboardText) return false;
    const parsedJson = safeJsonParse(clipboardText);
    if (!parsedJson) return false;
    return checkObjectIsJGState(parsedJson);
  }, [clipboardText]);
}

export function useCopyPasteState() {
  const { blocks, themes, selectedTheme } = useAppSelector(state => ({
    blocks: state.JGraphReducer.graph.blocks,
    themes: state.JGraphReducer.graph.themes,
    selectedTheme: state.JGraphReducer.selectedTheme,
  }));
  const busyPaths = useMemo(() => getAllStates(blocks).concat(themes.map(el => el.value)), [blocks, themes]);

  const dispatch = useAppDispatch();
  const stageObservableContext = useStageObservableContext();
  const clipboardText = useRef<string>();
  const parentPath = stageObservableContext.selectedGroupPath || selectedTheme?.value || '/';

  const copyState = useCallback((state: JStateWithId) => ClipboardService.copyToClipboard(JSON.stringify(state)), []);

  const createStateInner = useCallback(
    async (state: JStateWithId, pos: Vector2d) => {
      return dispatch(
        addNewStateWithSave({
          blocks: resetConnections(state.blocks),
          screenPath: state.value,
          parentStatePath: stageObservableContext.selectedGroupPath,
          theme: selectedTheme?.value,
          setEdit: true,
          position: pos,
        })
      );
    },
    [dispatch, selectedTheme?.value, stageObservableContext.selectedGroupPath]
  );

  const pasteState = useCallback(() => {
    if (!stageObservableContext.GStage) return;
    const screenPosition = stageObservableContext.GStage.getAbsolutePosition();
    const scale = stageObservableContext.GStage.scale() ?? { x: 1, y: 1 };
    const pointerPos = stageObservableContext.GStage.getPointerPosition();
    if (!pointerPos) return;
    const position = {
      x: (pointerPos.x - screenPosition.x) / scale.x,
      y: (pointerPos.y - screenPosition.y) / scale.y,
    };

    if (!clipboardText.current) return null;
    let parsedJson = safeJsonParse<JStateWithId>(clipboardText.current);
    if (!parsedJson) return null;
    if (!checkObjectIsJGState(parsedJson)) return null;

    const newPath = joinPaths(parsedJson.value, parentPath);
    parsedJson = stateChangePath(parsedJson, newPath);
    renameInlineStateIfNameIsBusy(parsedJson, busyPaths);

    return createStateInner(parsedJson, position);
  }, [stageObservableContext.GStage, parentPath, busyPaths, createStateInner]);

  useEffect(() => {
    return clipboardListener(text => {
      clipboardText.current = text;
    });
  }, []);

  return { copyState, pasteState };
}

export function useCopyToClipboardStateKeyboardHandlers(active: boolean) {
  const editMenuBlock = useRef<EditMenuBlock>();

  const { copyState, pasteState } = useCopyPasteState();

  const copyStateHandler = useCallback(() => {
    if (window.getSelection()?.toString()) return;
    if (
      !editMenuBlock.current ||
      !editMenuBlock.current.screen ||
      editMenuBlock.current.jBlockIndex !== undefined ||
      editMenuBlock.current.path !== undefined
    ) {
      return;
    }
    return copyState(editMenuBlock.current?.screen);
  }, [copyState]);

  useEffect(() => {
    if (!active) return;
    const sub = EditMenuBlock$.subscribe(value => {
      editMenuBlock.current = value;
    });
    const copyKeymap = ['ctrl + v', 'command + v'];
    keyboardJS.bind(copyKeymap, pasteState);

    return () => {
      sub.unsubscribe();
      keyboardJS.unbind(copyKeymap, pasteState);
    };
  }, [pasteState, active]);

  useEffect(() => {
    if (!active) return;
    const copyKeymap = ['ctrl + c', 'command + c'];
    keyboardJS.bind(copyKeymap, copyStateHandler);
    return () => {
      keyboardJS.unbind(copyKeymap, copyStateHandler);
    };
  }, [active, copyStateHandler]);
}
