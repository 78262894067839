export const imputerLocalization = {
  'Imputer: add replica button': {
    eng: 'Add phrase template',
    ru: 'Добавить шаблон реплики',
  },
  'Imputer: modal header label': {
    eng: 'New phrase template',
    ru: 'Новый шаблон реплики',
  },
  'Imputer: replica id input': {
    eng: 'Phrase template ID',
    ru: 'ID шаблона реплики',
  },
  'Imputer: replica voice input': {
    eng: 'Voice',
    ru: 'Голос',
  },
  'Imputer: replica file upload': {
    eng: 'Audio',
    ru: 'Аудио',
  },
  'Imputer: replica variable label': {
    eng: 'Phrase template text',
    ru: 'Текст шаблона реплики',
  },
  'Imputer: replica file upload description': {
    eng: 'The live speaker’s voice in the audio should be the same as the voice selected.',
    ru: 'Голос диктора из аудио должен быть идентичен выбранному голосу.',
  },
  'Imputer: replica file upload label first': {
    eng: 'Upload an audio file',
    ru: 'Загрузите аудиофайл',
  },
  'Imputer: replica file upload label second': {
    eng: ' or drag it here',
    ru: ' или перетащите его сюда',
  },
  'Imputer: replica file upload label third': {
    eng: ' or drag it here to add a phrase template',
    ru: ' или перетащите его сюда, чтобы добавить шаблон реплики',
  },
  'Imputer: replica audio validation duration': {
    eng: 'Duration up to 15 sec',
    ru: 'Длительность до 15 сек',
  },
  'Imputer: replica audio validation sampleRate': {
    eng: 'Sampling frequency from 22 kHz',
    ru: 'Частота дискретизации от 22 кГц',
  },
  'Imputer: replica audio validation bitPerSample': {
    eng: 'Depth from 16 bits',
    ru: 'Глубина от 16 бит',
  },
  'Imputer: replica audio validation channels': {
    eng: 'Mono channel',
    ru: 'Моноканал',
  },
  'Imputer: replica audio validation extension': {
    eng: 'WAV file',
    ru: 'Файл формата WAV',
  },
  'Imputer: replica file variables description': {
    eng: 'Enter the text from the audio and specify variables, for example: {hello: Hello}. A phrase template can contain up to 3 variables. You can specify them later.',
    ru: 'Введите текст из аудио и укажите переменные, например: {hello: Привет}. В шаблоне реплики может быть до 3 переменных. Вы можете указать их позже.',
  },
  'Imputer:errors:no-id': {
    eng: 'Specify phrase template ID',
    ru: 'Укажите ID шаблона реплики',
  },
  'Imputer:errors:duplicated-id': {
    eng: 'A phrase template with this ID already exists',
    ru: 'Шаблон реплики с таким ID уже существует',
  },
  'Imputer:errors:no-voiceId': {
    eng: 'Voice is not selected',
    ru: 'Голос не выбран',
  },
  'Imputer:errors:no-url': {
    eng: 'Upload an audio file',
    ru: 'Загрузите аудиофайл',
  },
  'Imputer:errors:no-message': {
    eng: 'Specify phrase template text',
    ru: 'Укажите текст шаблона реплики',
  },
  'Imputer:errors:duplicated-variables': {
    eng: 'If there are several variables in a phrase template, their values must be different',
    ru: 'Если в шаблоне реплики несколько переменных, то их значения должны быть разными',
  },
  'Imputer: delete modal title': {
    eng: 'Delete phrase template?',
    ru: 'Удалить шаблон реплики?',
  },
  'Imputer: delete modal body': {
    eng: 'Phrase template can be used in running scripts.',
    ru: 'Шаблон реплики может использоваться в текущих сценариях.',
  },
  'Imputer:BE-error:imputer.validation.duplicate_resource': {
    eng: 'A phrase template with this ID already exists',
    ru: 'Шаблон реплики с таким ID уже существует',
  },
  'Imputer: saving in progress': {
    eng: 'Saving...',
    ru: 'Сохраняется...',
  },
  'Imputer: saving error description': {
    eng: 'Such phrase template cannot be saved. Discard changes or continue editing?',
    ru: 'Шаблон реплики не может быть сохранен в текущем виде. Отменить изменения или продолжить редактирование?',
  },
  'Imputer: saving error cancel button': {
    eng: 'Discard changes',
    ru: 'Отменить изменения',
  },
  'Imputer: saving error continue button': {
    eng: 'Continue editing',
    ru: 'Продолжить редактирование',
  },
  'Imputer: saving error title': {
    eng: 'Error saving phrase template',
    ru: 'Ошибка сохранения шаблона реплики',
  },
  'Imputer: replica 1': {
    eng: 'phrase template',
    ru: 'шаблон реплики',
  },
  'Imputer: replica 2': {
    eng: 'phrase templates',
    ru: 'шаблона реплики',
  },
  'Imputer: replica 5': {
    eng: 'phrase templates',
    ru: 'шаблонов реплик',
  },
  'Imputer: search placeholder': {
    eng: 'Template ID, text, or variable name',
    ru: 'ID шаблона, текст или имя переменной',
  },
  'Imputer: all voices': {
    eng: 'All voices',
    ru: 'Все голоса',
  },
  'Imputer: configs variables label': {
    eng: 'Variable testing',
    ru: 'Тестирование переменных',
  },
  'Imputer: configs variables description': {
    eng: 'Change variable values to listen to the phrase.',
    ru: 'Меняйте значения переменных, чтобы прослушать, как будет звучать реплика.',
  },
  'Imputer: configs speed label': {
    eng: 'Speed',
    ru: 'Скорость',
  },
  'Imputer: configs speed description': {
    eng: 'Speed of voicing variables.',
    ru: 'Скорость произнесения переменных.',
  },
  'Imputer: configs main config label': {
    eng: 'Settings',
    ru: 'Настройки',
  },
  'Imputer: configs main config voice label': {
    eng: 'Voice',
    ru: 'Голос',
  },
  'Imputer: configs main config confirm label': {
    eng: 'Save changes',
    ru: 'Сохранить изменения',
  },
  'Imputer: configs main config audio default': {
    eng: 'Saved audio',
    ru: 'Сохраненное аудио',
  },
  'Imputer: configs main config audio label': {
    eng: 'Audio',
    ru: 'Аудио',
  },
  'Imputer:BE-error:imputer.worker.audio_generation_error': {
    eng: 'Generation error',
    ru: 'Ошибка генерации',
  },
  'Imputer:BE-error:ValidationError': {
    eng: 'Invalid variables',
    ru: 'Некорректная запись переменных',
  },
  'Imputer: send request header': {
    eng: 'Hybrid speech synthesis',
    ru: 'Гибридный синтез речи',
  },
  'Imputer: send request description to send': {
    eng: 'People prefer speaking with bots that use the live speaker’s voice rather than speech synthesis. Such bots speak more dynamically.\nHybrid synthesis technology allows replacing several words in audio recorded by a live speaker.\nDuring the call, the bot can call a client by name or voice their phone number. \nYou can find more information on speech synthesis with variables in the <a href="#{config.botadmin.helpUrl}/telephony/tts-with-variables/" target="_blank">documentation</a>.\nTo start using hybrid synthesis, $[1].\nAfter the service is connected, you can start working with it on this page.',
    ru: 'Людям больше нравится общаться с ботами, в которых используется дикторский голос, а не синтез речи. Такие боты говорят динамичнее и живее.\nТехнология гибридного синтеза позволяет заменить несколько слов в аудиозаписи, сделанной диктором, прямо во время звонка.\nНапример, бот сможет обратиться к клиенту по имени или озвучить номер телефона клиента.\nПодробнее о синтезе речи с переменными читайте в <a href="#{config.botadmin.helpUrl}/telephony/tts-with-variables/" target="_blank">документации</a>.\nЧтобы начать использовать гибридный синтез, $[1].\nПосле подключения на этой странице вы сможете работать с сервисом.',
  },
  'Imputer: send request description part no system feature': {
    eng: 'contact your Just AI manager',
    ru: 'свяжитесь с менеджером Just AI',
  },
  'Imputer: send request description part no project feature': {
    eng: 'send a request to your account manager',
    ru: 'отправьте запрос вашему аккаунт-менеджеру',
  },
  'Imputer: send request description sended': {
    eng: 'Your request is accepted. When we connect the service, we will notify you.',
    ru: 'Ваш запрос получен. Мы оповестим вас, как только подключим функцию.',
  },
  'Imputer: send request button': {
    eng: 'Connect',
    ru: 'Подключить',
  },
  'Imputer: send request confirm': {
    eng: 'Send',
    ru: 'Отправить',
  },
  'Imputer: send request modal title': {
    eng: 'Connection request',
    ru: 'Запрос на подключение',
  },
  'Imputer: send request company name label': {
    eng: 'Company name',
    ru: 'Название компании',
  },
  'Imputer: send request company name placehoder': {
    eng: 'Company',
    ru: 'Компания',
  },
  'Imputer: unknown error': {
    eng: 'Unknown error in the phrase template. Please, contact our technical support.',
    ru: 'Неизвестная ошибка в шаблоне реплики. Пожалуйста, свяжитесь с технической поддержкой.',
  },
  'Imputer: replica config invalid label': {
    eng: 'Testing will be unavailable until you fix the error in the phrase template.',
    ru: 'Тестирование будет недоступно, пока вы не исправите ошибку в шаблоне реплики.',
  },
  'Imputer: replica id placeholder': {
    eng: 'Phrase template ID',
    ru: 'ID шаблона реплики',
  },
  'Imputer: replica variables placeholder': {
    eng: 'The phrase template text should match the text from the audio',
    ru: 'Текст шаблона реплики должен совпадать с текстом из аудио',
  },
  'Imputer: add replica modal cancel button': {
    eng: 'Cancel',
    ru: 'Отменить',
  },
  'Imputer: warning variables to many words': {
    eng: 'Variables contain more than 2 words. Unwanted audio effects may arise when synthesizing.',
    ru: 'Переменные содержат больше 2 слов. При синтезе могут возникнуть нежелательные аудиоэффекты.',
  },
  'Imputer: Replica var description': {
    eng: 'Use { } to specify a variable, for example: {hello: Hello}. A phrase template can contain up to 3 variables.',
    ru: 'Используйте { }, чтобы указать переменную, например: {hello: Добрый день}. В шаблоне реплики может быть до 3 переменных.',
  },
  'Imputer: Replica config variables placeholder': {
    eng: 'Add variables to a phrase template to listen to other phrase options.',
    ru: 'Добавьте переменные в шаблон реплики, чтобы прослушать реплику в других вариантах.',
  },
  'Imputer: Replica config speed placeholder': {
    eng: 'Add variables to a phrase template to listen to them at different speeds.',
    ru: 'Добавьте переменные в шаблон реплики, чтобы прослушать их при разной скорости.',
  },
  'Imputer: file config file description': {
    eng: 'or drag to replace the current one.',
    ru: 'или перетащите для замены текущего.',
  },
  'Imputer: config placeholder': {
    eng: 'Select a template \nto test and configure a phrase.',
    ru: 'Выберите шаблон, чтобы перейти \nк тестированию и настройке реплики.',
  },
  'Imputer: token copy label description': {
    eng: 'To use synthesis outside of JAICP via API',
    ru: 'Для использования синтеза вне JAICP через API',
  },
  'Imputer: copy replica generate url tooltip': {
    eng: 'Copy the link for phrase generation via API',
    ru: 'Скопировать ссылку для генерации реплики через API',
  },
  'Imputer: replica replica id placeholder': {
    eng: 'Select voice',
    ru: 'Выберите голос',
  },
  'Imputer: create report label': {
    eng: 'Download report',
    ru: 'Скачать отчет',
  },
  'Imputer: create report label description': {
    eng: 'API requests per day',
    ru: 'Количество вызовов API \nпо дням',
  },
};
