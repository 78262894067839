export const channelTypeList = {
  voiceAssistants: ['YANDEX', 'GOOGLE', 'MARUSIA', 'JAICP_ALEXA', 'DIALOGFLOW', 'ZENBOX', 'SBER'],
  messengers: [
    'VK',
    'TELEGRAM',
    'VIBER',
    'FACEBOOK',
    'INSTAGRAM',
    'SLACK',
    'WECHAT',
    'ODNOKLASSNIKI',
    'NEXMO',
    'I_DIGITAL',
    'EDNA_PLATFORM',
    'WAZZUP',
    'WHATSAPP',
  ],
  telephone: ['PROMPTER_API', 'LIVETEX_PROMPTER'],
  others: [
    'CHAT_API',
    'CHAT_WIDGET',
    'ZENDESK',
    'ZOPIM',
    'BITRIX',
    'CHAT2DESK',
    'THREADS',
    'AZURE',
    'INCOMING_JIVOSITE',
    'WEBIM',
    'INCOMING_WEBIM2',
    'INBOUND_LIVETEX',
  ],
};

export const operatorChannelTypeList = [
  'LIVETEX',
  'LIVETEX_VISITOR',
  'CHAT2DESK',
  'SALESFORCE',
  'JIVOSITE',
  'OPERATORAPI',
  'WEBIM',
  'AIMYCHAT',
];
