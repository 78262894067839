import { useEffect, useState } from 'react';

import { clipboardListener } from 'utils/hooks/CopyToClipboardFeature';
import safeJsonParse from 'utils/safeJsonParse';

import { ClipboardService } from 'modules/JGraph/services/ClipboardService';

import { IntentItem } from '../../model';

type PhrasesStoredType = {
  entityName: 'intentPhrases';
  payload: IntentItem[];
};
type Serializer<DATA = any> = {
  serialize(data: DATA): string;
  deserialize(str: string): DATA | undefined;
};

const intentPhrasesSerializer: Serializer<IntentItem[]> = {
  deserialize: str => {
    const parsed = safeJsonParse<PhrasesStoredType>(str);
    if (!parsed) return;
    if (parsed.entityName === 'intentPhrases' && parsed.payload && Array.isArray(parsed.payload)) {
      return parsed.payload;
    }
    return;
  },
  serialize: phrases =>
    JSON.stringify({
      entityName: 'intentPhrases',
      payload: phrases,
    } as PhrasesStoredType),
};

export const copyTrainingPhrasesToClipboard = (phrases: IntentItem[]) =>
  ClipboardService.copyToClipboard(intentPhrasesSerializer.serialize(phrases));

export function useTrainingPhrasesFromClipboard() {
  const [phrasesList, setPhrasesList] = useState<IntentItem[] | null>(null);

  useEffect(() => {
    return clipboardListener(text => {
      const parsed = intentPhrasesSerializer.deserialize(text);
      setPhrasesList(parsed ?? null);
    });
  }, []);

  return phrasesList;
}
