import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon';
import { getSelectedItem } from './SelectedItem';
import cn from 'classnames';
export const JustSelectContainer = ({ focused, multiple, onFocus, selected, inputPlaceholder, searchValue, onChange, selectedItemComponent, selectedItemComponentProps, backSpaceDeleteSelected, iconLeft, deleteSelectedItem, cleanupSelected, autoFocus, iconArrowShow = true, invalid, disabled, withoutSearch, size = 'md', dropdownIconName = 'faJustSelect', hideMultipleSelectedOptions, dataTestId, closeable, onValueClick, blockHtml, }) => {
    var _a, _b;
    const SelectedItemComponent = getSelectedItem(selectedItemComponent, selectedItemComponentProps);
    const inputElement = useRef(null);
    const deleteSelection = useCallback(selectedItem => () => {
        deleteSelectedItem(selectedItem);
    }, [deleteSelectedItem]);
    const handleClickArrow = (evt) => {
        const previousNode = evt.currentTarget.previousSibling;
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'DIV') {
            const justSelect = previousNode.previousSibling;
            justSelect === null || justSelect === void 0 ? void 0 : justSelect.focus();
        }
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'SPAN') {
            previousNode === null || previousNode === void 0 ? void 0 : previousNode.focus();
        }
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'INPUT') {
            previousNode === null || previousNode === void 0 ? void 0 : previousNode.focus();
        }
    };
    const styleIconCleanUp = iconArrowShow ? { right: '2rem' } : {};
    useEffect(() => {
        var _a;
        if (autoFocus) {
            (_a = inputElement.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    return (_jsx("div", { className: classNames(`just-select-container ${invalid ? 'error' : ''} ${size}`, { focused: focused }, { multiple: multiple }, { closeable }, { disabled: disabled }), children: !multiple ? (_jsxs(_Fragment, { children: [iconLeft && _jsx(Icon, { wrapperClassName: 'leftIcon', name: iconLeft, size: size }), withoutSearch ? (_jsx("span", { className: cn('single', {
                        withLeftIcon: iconLeft,
                    }), tabIndex: 0, onKeyDown: e => {
                        e.preventDefault();
                    }, onFocus: onFocus, "data-test-id": `${dataTestId ? dataTestId + '_' : ''}just-select__single`, dangerouslySetInnerHTML: blockHtml ? undefined : { __html: (_a = selected === null || selected === void 0 ? void 0 : selected[0]) === null || _a === void 0 ? void 0 : _a.label },
                    children: blockHtml ? (_b = selected === null || selected === void 0 ? void 0 : selected[0]) === null || _b === void 0 ? void 0 : _b.label : undefined })) : (_jsx("input", { ref: inputElement, type: 'text', className: cn('single', {
                        withLeftIcon: iconLeft,
                    }), "data-test-id": `${dataTestId ? dataTestId + '_' : ''}just-select__single_search`, onFocus: onFocus, placeholder: selected.length > 0 ? selected[0].label : inputPlaceholder, value: selected.length > 0 && !focused && !searchValue ? selected[0].label : searchValue, onChange: onChange, onSubmit: e => e.stopPropagation(), autoFocus: autoFocus, "aria-invalid": invalid, disabled: disabled })), _jsx("div", { className: 'just-select-container-open', onClick: handleClickArrow, children: _jsx(Icon, { name: dropdownIconName, size: size }) })] })) : (_jsxs(_Fragment, { children: [!hideMultipleSelectedOptions &&
                    (selected === null || selected === void 0 ? void 0 : selected.map(selectedItem => (_jsx(SelectedItemComponent, { label: selectedItem.label, onDelete: deleteSelection(selectedItem), onClick: onValueClick ? () => onValueClick(selectedItem.value) : undefined, blockHtml: blockHtml }, `selectedItem${selectedItem.value}`)))), _jsx("input", { ref: inputElement, type: 'text', className: 'multiple', "data-test-id": `${dataTestId ? dataTestId + '_' : ''}just-select__multiple_search`, onFocus: onFocus, autoFocus: autoFocus, onChange: onChange, onKeyDown: backSpaceDeleteSelected, value: searchValue || '', onSubmit: e => e.stopPropagation(), "aria-invalid": invalid, disabled: disabled, placeholder: !hideMultipleSelectedOptions && selected.length === 0 ? inputPlaceholder : '' }), !hideMultipleSelectedOptions && selected.length > 0 && closeable && (_jsx("div", { className: 'just-select-selected-item-cleanup', onClick: cleanupSelected, style: styleIconCleanUp, children: _jsx(Icon, { name: 'faTimesCircle', size: size }) })), iconArrowShow && (_jsx("div", { className: 'just-select-container-open', "data-test-id": 'just-select-container-open-icon', onClick: handleClickArrow, children: _jsx(Icon, { name: dropdownIconName, size: size }) }))] })) }));
};
